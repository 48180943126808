export function getHostname(hostname: string) {
  if (hostname.includes('localhost')) {
    // return 'http://localhost:3000';
    return 'https://stg.api.a2a56.com';
    // return 'https://a2a56-stg-api.herokuapp.com/';
  }

  if (hostname.includes('staging') || hostname.includes('netlify')) {
    return 'https://stg.api.a2a56.com';
  }

  return 'https://api.a2a56.com';
}
