import { Badge, Box, Flex, HStack, Image, Pressable, Spacer, Text, VStack } from 'native-base';
import '../styles/landing.css';
import { SearchInput } from './SearchInput';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { HelpCenter } from './HelpCenter';

export const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="landing_container landing_gradient" style={{ zIndex: 3 }}>
        <Navbar upper={true} />
        <div className="landing_image_container landing_gradient">
          <Box width="35%" left="16%" top="144px">
            <Text color="#ffb31c" textAlign="left" fontStyle="italic" fontWeight={800} fontSize={window.innerWidth < 1920 ? "53px" : "60px"} fontFamily="Segoe UI">{t('landing_header')}</Text>
            <Text color="#ffffff" fontSize="25px" fontFamily="Segoe UI" width="573px" mb="20px">{t('landing_text')}</Text>
            <SearchInput />
          </Box>
        </div>
      </div>
      <div className="landing_container">
        <div className="navbar_shadow">
          <Navbar upper={false} />
        </div>
        <Box width="77%" pt="90px">
          <HStack width="100%" justifyContent="space-between">
            <Image ml={5} source={{ uri: `${process.env.PUBLIC_URL}/icons/contactUs.svg`, }} alt="Sobre despacho" width="520px" height="421px" />
            <VStack flex={1}>
              <Text width="60%" textAlign="left" fontStyle="italic" fontSize="60px" fontWeight="bold" color="#293D8B" fontFamily="Segoe UI" letterSpacing="0px">{t('get_in_touch')}</Text>
              <Text flexWrap="wrap" w="100%" pt="10px" mb="30px" textAlign="left" fontSize="20px" fontFamily="Segoe UI" color="#000000">{t('get_in_touch_description')}</Text>
              {/*@ts-ignore */}
              <Pressable onPress={() => navigate('/contact')}>
                {({
                  isHovered,
                }) => {
                  return <Box p={0} m={0} width="154px" height="47px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} borderRadius="23px" display="flex" flexDir="row" alignItems="center" justifyContent="center" >
                    <Text fontSize="16px" fontWeight="bold" fontFamily="Segoe UI" color="#293d8b" textAlign="center" textTransform="uppercase" letterSpacing="0px">{t('contact')}</Text>
                  </Box>;
                }}
              </Pressable>
            </VStack>
          </HStack>
        </Box>
        <HelpCenter />
        <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
          <Footer />
        </Box>
      </div>
    </>
  );
};
