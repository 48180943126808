export const getDateFormat = (date: string | undefined): string => {
  if (!date) {
    return '';
  }

  let validTimestamp = null;
  if (date.indexOf('.') > 0) {
    validTimestamp = date.split('.')[0] + '+00:00';
  } else if (date.indexOf('Z') > 0) {
    validTimestamp = date.split('Z')[0] + '+00:00';
  } else {
    validTimestamp = date;
  }
  const months = {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
  };
  const f_date = new Date(validTimestamp).toString().split(' ');
  if (f_date.length > 0) {
    // @ts-ignore
    if (months[f_date[1]] === undefined) {
      return '';
    }
    // @ts-ignore
    return `${months[f_date[1]]} ${f_date[2]}, ${f_date[3]}`;
  }
  return '';
};

export const getHourFormat = (date: string | undefined): string => {
  if (!date){
    return '';
  }

  let validTimestamp = null;
  if (date.indexOf('.') > 0) {
    validTimestamp = date.split('.')[0] + '+00:00';
  } else if (date.indexOf('Z') > 0) {
    validTimestamp = date.split('Z')[0] + '+00:00';
  } else {
    validTimestamp = date;
  }
  let time: any = new Date(validTimestamp).toString().split(' ')[4];
  if (time) {
    time = time.split(':');
    if (Number(time[0]) > 12) {
      return `${Number(time[0]) - 12}:${time[1]}:${time[2]} PM`;
    } else {
      return `${time[0]}:${time[1]}:${time[2]} AM`;
    }
  }
  return '';
};
