const SearchSvg = () => {
  return ( 
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_101_1539)">
      <path d="M22.3326 22.2774L29.0394 28.9641L22.3326 22.2774Z" fill="#F7941E"/>
      <path d="M29.0394 29.711C28.8404 29.711 28.6531 29.641 28.501 29.4893L21.7942 22.8025C21.5016 22.5108 21.5016 22.0323 21.7942 21.7406C22.0868 21.4489 22.5667 21.4489 22.8593 21.7406L29.5661 28.4273C29.8587 28.7191 29.8587 29.1975 29.5661 29.4893C29.4256 29.641 29.2266 29.711 29.0394 29.711Z" fill="white"/>
      <path d="M38.1338 33.9471L30.5141 26.3501C29.3553 25.1948 27.4708 25.1948 26.3121 26.3501C25.7502 26.9103 25.4459 27.6571 25.4459 28.439C25.4459 29.2325 25.7502 29.9794 26.3121 30.5395L33.9318 38.1365C34.5053 38.7083 35.2779 39 36.0387 39C36.7995 39 37.5603 38.7083 38.1338 38.1365C39.2926 36.9812 39.2926 35.1024 38.1338 33.9471Z" fill="#013177"/>
      <path d="M12.7464 5.4614C13.1561 5.44973 13.4721 5.11131 13.4721 4.71454V0.758528C13.4721 0.560144 13.3902 0.361759 13.238 0.221724C13.0975 0.0816876 12.8986 0 12.6996 0C9.34033 0.0933573 6.19177 1.44704 3.81572 3.81598C-1.27581 8.89228 -1.27581 17.1544 3.81572 22.2424C6.19177 24.6113 9.34033 25.965 12.6879 26.0583C12.6996 26.0583 12.6996 26.0583 12.7113 26.0583C12.9103 26.0583 13.0975 25.9767 13.238 25.8483C13.3784 25.7083 13.4721 25.5099 13.4721 25.3115V21.3555C13.4721 20.947 13.1561 20.6203 12.7464 20.6086C10.8385 20.5269 9.04772 19.7451 7.68997 18.4031C4.72869 15.4506 4.72869 10.6544 7.68997 7.70198C9.04772 6.32496 10.8385 5.54309 12.7464 5.4614Z" fill="#F7941E"/>
      <path d="M22.2974 3.81599C19.7341 1.26033 16.3163 -0.0933483 12.6996 8.94628e-06V5.46141C12.723 5.46141 12.7347 5.46141 12.7464 5.46141C14.865 5.37972 16.925 6.18493 18.4232 7.66698C21.3845 10.6194 21.3845 15.4156 18.4232 18.3681C16.925 19.8618 14.8532 20.667 12.7464 20.5736C12.7347 20.5736 12.7113 20.5736 12.6996 20.5736V26.035C12.8283 26.035 12.9454 26.035 13.0741 26.035C16.5621 26.035 19.8277 24.693 22.2974 22.219C27.389 17.1544 27.389 8.89229 22.2974 3.81599Z" fill="#FBB040"/>
      </g>
      <defs>
      <clipPath id="clip0_101_1539">
      <rect width="39" height="39" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )}
  
  export default SearchSvg;