import { Text, Image, HStack, Pressable, VStack } from 'native-base';
import { TrackingNumberResultHash } from '../screens/ResultScreen';
import { StateTab } from '../types';

const TabDictionary: Record<StateTab, number> = {
  all: 0,
  delivered: 1,
  in_transit: 2,
  expired: 3,
  alert: 4,
  'not-found': 5,
};

type TabRenderable = {
  src?: string;
  filter: keyof typeof TabDictionary;
  tallyCounter: (
    results: TrackingNumberResultHash['trackingResult'][],
  ) => number;
  title: string;
};

type Props = {
  trackingNumberResultHashList: TrackingNumberResultHash[];
  activeFilter: StateTab;
  setActiveFilter: (s: StateTab) => any;
};

const tabs: TabRenderable[] = [
  {
    // src: `${process.env.PUBLIC_URL}/icons/icons8-all-64.png`,
    filter: 'all',
    tallyCounter: results => results.length,
    title: '所有',
  },
  {
    src: `${process.env.PUBLIC_URL}/icons/delivered.svg`,
    filter: 'delivered',
    tallyCounter: results =>
      results.filter(r => r?.status === 'delivered').length,
    // title: '派送成功',
    title: '',
  },
  {
    src: `${process.env.PUBLIC_URL}/icons/transit.svg`,
    filter: 'in_transit',
    tallyCounter: results =>
      results.filter(r => r?.status === 'in_transit').length,
    // title: '在途中',
    title: '',
  },
  {
    src: `${process.env.PUBLIC_URL}/icons/no_change.svg`,
    filter: 'expired',
    tallyCounter: results =>
      results.filter(r => r?.status === 'expired').length,
    // title: '长时间未更新',
    title: '',
  },
  {
    src: `${process.env.PUBLIC_URL}/icons/warning.svg`,
    filter: 'alert',
    tallyCounter: results => results.filter(r => r?.status === 'alert').length,
    // title: '异常',
    title: '',
  },
  {
    src: `${process.env.PUBLIC_URL}/icons/no_trace.svg`,
    filter: 'not-found',
    tallyCounter: results =>
      results.filter(r => r?.status === 'not-found').length,
    // title: '未知号码/无规迹',
    title: '',
  },
];

const ResultTally: React.FC<Props> = ({
  trackingNumberResultHashList,
  activeFilter,
  setActiveFilter,
}) => {
  const handleTabChange = (tab: TabRenderable['filter']) => {
    if (tab === activeFilter) {
      return;
    }

    setActiveFilter(tab);
  };

  const getWidth = (): string => {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
    if (width >= 1060) {
      return '60%';
    }
    if (width > 820 && width < 1060) {
      return '80%';
    }
    return '100%';
  };

  return (
    <HStack
      ml="20px"
      // mt={4}
      width={getWidth()}
      justifyContent="space-between">
      {tabs.map(tab => {
        return (
          <Pressable
            //@ts-ignore
            onPress={() => handleTabChange(tab.filter)}
            key={tab.filter}
            borderBottomWidth="2px"
            borderBottomColor={activeFilter === tab.filter ? "#293d8b" : "#ffffff"}
            pl={1}
            pr={1}
            pb={2}>
            <HStack alignItems="center" justifyContent="space-between" width="50px">
              {tab.src && (
                <Image
                  src={tab.src}
                  alt={`${tab.filter}_icon`}
                  width="25px"
                  height="25px"
                />
              )}
              <Text textAlign="center" fontSize={12} bold>
                {tab.title}
              </Text>
              <Text textAlign="center" fontSize={12}>
                (
                {tab.tallyCounter(
                  trackingNumberResultHashList.map(r => r.trackingResult),
                )}
                )
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </HStack>
  );
};

export default ResultTally;
