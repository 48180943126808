import i18next from '../i18n';
export const paragraphs = [
    {type: 'title', text: i18next.t('help_1')},
    {type: 'text', text: i18next.t('help_2')},
    {type: 'text', text: i18next.t('help_3')},
    {type: 'text', text: i18next.t('help_4')},
    {type: 'text', text: i18next.t('help_5')},
    {type: 'text', text: i18next.t('help_6')},
    {type: 'text', text: i18next.t('help_7')},
    {type: 'text', text: i18next.t('help_8')},
    {type: 'text', text: i18next.t('help_9')},
    {type: 'text', text: i18next.t('help_10')},
    {type: 'text', text: i18next.t('help_11')},
    {type: 'title', text: i18next.t('help_12')},
    {type: 'text', text: i18next.t('help_13')},
    {type: 'text', text: i18next.t('help_14')},
    {type: 'text', text: i18next.t('help_15')},
    {type: 'title', text: i18next.t('help_16')},
    {type: 'text', text: i18next.t('help_17')},
    {type: 'text', text: i18next.t('help_18')},
    {type: 'title', text: i18next.t('help_19')},
    {type: 'text', text: i18next.t('help_20')},
    {type: 'text', text: i18next.t('help_21')},
    {type: 'text', text: i18next.t('help_22')},
    {type: 'title', text: i18next.t('help_23')},
    {type: 'text', text: i18next.t('help_24')},
    {type: 'title', text: i18next.t('help_25')},
    {type: 'text', text: i18next.t('help_26')},
    {type: 'text', text: i18next.t('help_27')},
    {type: 'title', text: i18next.t('help_28')},
    {type: 'text', text: i18next.t('help_29')},
    {type: 'text', text: i18next.t('help_30')},
    {type: 'text', text: i18next.t('help_31')},
    {type: 'title', text: i18next.t('help_32')},
    {type: 'text', text: i18next.t('help_33')},
    {type: 'text', text: i18next.t('help_34')},
    {type: 'title', text: i18next.t('help_35')},
    {type: 'text', text: i18next.t('help_36')},
    {type: 'text', text: i18next.t('help_37')},
    {type: 'title', text: i18next.t('help_38')},
    {type: 'text', text: i18next.t('help_39')},
    {type: 'text', text: i18next.t('help_40')},
    {type: 'text', text: i18next.t('help_41')},
    {type: 'title', text: i18next.t('help_42')},
    {type: 'text', text: i18next.t('help_43')},
    {type: 'title', text: i18next.t('help_44')},
    {type: 'text', text: i18next.t('help_45')},
    {type: 'title', text: i18next.t('help_46')},
    {type: 'text', text: i18next.t('help_47')},
    {type: 'text', text: i18next.t('help_48')},
    {type: 'title', text: i18next.t('help_49')},
    {type: 'text', text: i18next.t('help_50')},
    {type: 'text', text: i18next.t('help_51')},
    {type: 'title', text: i18next.t('help_52')},
    {type: 'text', text: i18next.t('help_53')},
    {type: 'text', text: i18next.t('help_54')},
    {type: 'text', text: i18next.t('help_55')},
    {type: 'title', text: i18next.t('help_56')},
    {type: 'text', text: i18next.t('help_57')},
    {type: 'text', text: i18next.t('help_58')},
    {type: 'title', text: i18next.t('help_59')},
    {type: 'text', text: i18next.t('help_60')},
    {type: 'title', text: i18next.t('help_61')},
    {type: 'text', text: i18next.t('help_62')},
    {type: 'title', text: i18next.t('help_63')},
    {type: 'text', text: i18next.t('help_64')},
    {type: 'title', text: i18next.t('help_65')},
    {type: 'text', text: i18next.t('help_66')},
    {type: 'title', text: i18next.t('help_67')},
    {type: 'text', text: i18next.t('help_68')},
    {type: 'text', text: i18next.t('help_69')},
    {type: 'title', text: i18next.t('help_70')},
    {type: 'text', text: i18next.t('help_71')},
    {type: 'text', text: i18next.t('help_72')},
]

export const getHelpParagraph = (id: number) => {
    console.log(id)
    let idCount = 0;
    let title = "";
    const par:string[] = [];
    paragraphs.map((el) => {
        if(el.type === 'title') {
            idCount ++;
        }
        if(idCount === Number(id)) {
            if(el.type === 'title') {
                title = el.text;
            } else {
                par.push(el.text)
            }
        }
        return el;
    })
    return {
        title,
        paragraphs: par
    }
}