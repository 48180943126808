import { Box, HStack, Image, Pressable, Text, VStack } from "native-base"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { SearchInput } from "../components/SearchInput";
import { getHelpParagraph, paragraphs } from "../helper/help.helper";

export const HelpQuestion = () => {
    const param = useParams();
    const [title, setTitle] = useState("")
    const [text, setText] = useState<string[]>([])
    const [initLoad, setInitLoad] = useState<boolean>(true)
    useEffect(() => {
        if(initLoad){
        setInitLoad(false)
        // @ts-ignore
        const t = getHelpParagraph(param.id)
        setTitle(t.title);
        setText(t.paragraphs)
        }
    },[initLoad, param.id])
    const { t } = useTranslation();
    return <Box>
        <div className="landing_container landing_gradient" style={{ zIndex: 3, height: '482px' }}>
            <Navbar upper={true} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
                <div style={{ width: "194px", height: "194px", position: 'relative', marginLeft: '100px', marginTop: "45px" }}>
                    <Image
                        width={'194px'}
                        height={'194px'}
                        style={{ position: 'absolute' }}
                        resizeMode={'cover'}
                        source={{
                            uri: `${process.env.PUBLIC_URL}/icons/help/help_1.svg`,
                        }}
                    />
                </div>

                <Box width="40%">
                    <Text color="#ffb31c" textAlign="center" fontStyle="italic" fontWeight={800} fontFamily="Segoe UI" fontSize={window.innerWidth < 1920 ? "53px" : "60px"}>{t('hy')}</Text>
                    <Text color="#ffffff" textAlign="center" bold fontStyle="italic" fontSize={window.innerWidth < 1920 ? "43px" : "50px"} fontFamily="Segoe UI" mb="20px">{t('how_can_we_help_you')}</Text>
                    <Text color="#ffffff" textAlign="center" fontSize="20px" fontFamily="Segoe UI" mb="20px">{t('search_in_our')}</Text>
                    <SearchInput onlyArrow />
                </Box>
                <div style={{ width: "194px", height: "194px", position: 'relative', marginRight: '100px', marginTop: "45px" }}>
                    <Image
                        width={'194px'}
                        height={'194px'}
                        style={{ position: 'absolute' }}
                        resizeMode={'cover'}
                        source={{
                            uri: `${process.env.PUBLIC_URL}/icons/help/help_2.svg`,
                        }}
                    />
                </div>
            </div>
        </div>
        <VStack mx={"auto"} width="655px" mt={30} mb={30}>
            <Link to={`/help`} style={{ textDecoration: 'none', width: '100%' }}>
                <Pressable p={0} m={0}>
                    {({ isHovered }) => {
                        return (
                            <HStack width="100%">
                                <Image
                                    width={'22px'}
                                    height={'22px'}
                                    resizeMode={'cover'}
                                    source={{
                                        uri: `${process.env.PUBLIC_URL}/icons/help/arrow.svg`,
                                    }}
                                />
                                <Text textDecorationLine={ isHovered ? 'underline' : "none"} fontSize="17px" color="#1264A3" ml="10px">{t('help_center_button')}</Text>
                            </HStack>
                        )
                    }}
                </Pressable>
            </Link>
            <Text color="#293d8b" mt={30} bold fontSize={25}>{title}</Text>
        </VStack>
        <VStack maxWidth="60%" width="655px" mx={'auto'} flexWrap="wrap">
            {text.map(el => <Text fontSize="20px">{el}</Text>)}
        </VStack>
        <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
            <Footer />
        </Box>
    </Box>
}