const TruckSvg = () => {
  return ( 
    <svg width="81" height="55" viewBox="0 0 81 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M76.9155 27.1091L76.7103 26.8785C71.8375 21.3689 64.8103 18.1913 57.4497 18.1913C56.9367 18.1913 56.5264 18.6013 56.5007 19.1138L56.2699 37.8465H5.36128C4.84834 37.8465 4.41235 38.2565 4.41235 38.7946V46.4312C4.41235 46.9437 4.8227 47.3793 5.36128 47.3793H79.3263C79.8392 47.3793 80.2752 46.9693 80.2752 46.4312V35.9245C80.2496 32.67 79.0698 29.5436 76.9155 27.1091Z" fill="#FBB040"/>
      <path d="M46.1908 0.45813H7.46437C3.38655 0.45813 0.078125 3.76388 0.078125 7.83842V32.4394C0.078125 36.514 3.38655 39.8197 7.46437 39.8197H46.1908C50.2687 39.8197 53.5771 36.514 53.5771 32.4394V7.83842C53.5771 3.76388 50.2687 0.45813 46.1908 0.45813Z" fill="#013177"/>
      <path d="M72.7864 47.687C72.7864 50.8646 70.196 53.4529 67.0159 53.4529C63.8357 53.4529 61.2454 50.8646 61.2454 47.687C61.2454 44.5094 63.8357 41.9211 67.0159 41.9211C70.2217 41.9211 72.7864 44.5094 72.7864 47.687Z" fill="#013177"/>
      <path d="M67.0416 54.6828C63.1689 54.6828 60.0144 51.5308 60.0144 47.6613C60.0144 43.7918 63.1689 40.6398 67.0416 40.6398C70.9142 40.6398 74.0688 43.7918 74.0688 47.6613C74.0431 51.5565 70.8886 54.6828 67.0416 54.6828ZM67.0416 43.1767C64.5539 43.1767 62.5278 45.2012 62.5278 47.6869C62.5278 50.1727 64.5539 52.1971 67.0416 52.1971C69.5293 52.1971 71.5554 50.1727 71.5554 47.6869C71.5554 45.2012 69.5293 43.1767 67.0416 43.1767Z" fill="#013177"/>
      <path d="M46.2164 0.560669H7.56689C3.51472 0.560669 0.206299 3.78955 0.206299 7.76159V21.2921H53.5513V7.76159C53.577 3.78955 50.2686 0.560669 46.2164 0.560669Z" fill="#074EA3"/>
      <path d="M44.1391 20.472L32.1108 10.1448C31.7517 9.83724 31.2131 9.76036 30.7772 9.96537C30.3412 10.1704 30.059 10.606 30.059 11.0929V15.9362H10.3368C9.6443 15.9362 9.08008 16.5 9.08008 17.1919V25.0078C9.08008 25.6998 9.6443 26.2635 10.3368 26.2635H30.0078V31.1068C30.0078 31.5937 30.2899 32.0294 30.7259 32.2344C30.9054 32.3113 31.0849 32.3625 31.2644 32.3625C31.5465 32.3625 31.8287 32.26 32.0595 32.0806L44.0878 22.3684C44.3699 22.1377 44.5494 21.779 44.5494 21.4202C44.5751 21.0614 44.4212 20.7283 44.1391 20.472Z" fill="#FBB040"/>
      <path d="M79.8136 32.5419C79.2751 30.543 78.2748 28.6467 76.9155 27.1092L76.7104 26.8785C73.4276 23.1627 69.1446 20.472 64.3743 19.1395C64.0922 19.0626 63.7845 19.1138 63.5536 19.2932C63.3228 19.4726 63.1689 19.7545 63.1689 20.0364V32.7725C63.1689 33.285 63.5793 33.7207 64.1179 33.7207H78.916C79.1981 33.7207 79.4802 33.5925 79.6597 33.3619C79.8393 33.1313 79.8906 32.8238 79.8136 32.5419Z" fill="#013177"/>
      <path d="M15.8765 40.6654C12.0039 40.6654 8.84937 43.8174 8.84937 47.6869C8.84937 51.5565 12.0039 54.7085 15.8765 54.7085C19.7492 54.7085 22.9037 51.5565 22.9037 47.6869C22.9037 43.8174 19.7492 40.6654 15.8765 40.6654Z" fill="#013177"/>
      <path d="M67.0416 40.6654C63.1689 40.6654 60.0144 43.8174 60.0144 47.6869C60.0144 51.5565 63.1689 54.7085 67.0416 54.7085C70.9142 54.7085 74.0688 51.5565 74.0688 47.6869C74.0431 43.8174 70.8886 40.6654 67.0416 40.6654Z" fill="#013177"/>
      <path d="M9.08008 21.1127V25.0335C9.08008 25.7254 9.6443 26.2891 10.3368 26.2891H30.0078V31.1324C30.0078 31.6193 30.2899 32.055 30.7259 32.26C30.9054 32.3369 31.0849 32.3881 31.2644 32.3881C31.5466 32.3881 31.8287 32.2856 32.0595 32.1062L44.0878 22.394C44.3699 22.1633 44.5494 21.8046 44.5494 21.4458C44.5494 21.3433 44.5238 21.2152 44.4981 21.1127H9.08008Z" fill="#F7941E"/>
      <path d="M15.8766 42.7155C13.1324 42.7155 10.9268 44.9449 10.9268 47.6613C10.9268 50.4033 13.158 52.6071 15.8766 52.6071C18.6208 52.6071 20.8264 50.3776 20.8264 47.6613C20.852 44.9449 18.6208 42.7155 15.8766 42.7155Z" fill="#074EA3"/>
      <path d="M67.0416 42.7155C64.2974 42.7155 62.0918 44.9449 62.0918 47.6613C62.0918 50.4033 64.3231 52.6071 67.0416 52.6071C69.7858 52.6071 71.9914 50.3776 71.9914 47.6613C71.9914 44.9449 69.7601 42.7155 67.0416 42.7155Z" fill="#074EA3"/>
    </svg>
  )}
  
  export default TruckSvg;
  