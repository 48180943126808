import {
  Box,
  Heading,
  HStack,
  Center,
  VStack,
  Text,
  Image,
} from 'native-base';
import Footer from "../components/Footer";
import ClockSvg from '../svg/clock';
import SearchSvg from '../svg/search';
import TodoSvg from '../svg/todo';
import TruckSvg from '../svg/truck';

const ServiceScreen = () => {
  return (
    <>
      <Box h={'100vh'} >
      <Image
        source={`${process.env.PUBLIC_URL}/services.png`}
        flex={1}
        resizeMode='cover'
        position= 'absolute'
        width= '100%'
        height= '100%'
        justifyContent='center'
        />
        <VStack>
        <Heading color='white'
          position='relative'
          pl={'15vh'}
          pt={'30vh'}
          fontFamily='Roboto'
          fontWeight={900}
          fontSize={64}
        >
          Nuestros
        </Heading>
        <Heading color='white'
          position='relative'
          pl={'15vh'}
          fontFamily='Roboto'
          fontWeight={900}
          fontSize={64}
        >
          Servicios
        </Heading>
        <Box pt={4}
        pl={'15vh'}
        maxW={'60vh'}
        >
          <Text color='white' 
          fontFamily='Roboto'
          fontSize={16}
          >
          Lorem ipsum dolor sit amet, 
          consectetur adipiscing elit, 
          ut labore et dolore magna aliqua. 
          sed do eiusmod tempor incididunt 
          </Text>
        </Box>
        </VStack>
      </Box>
      <Box bg={'#E5E5E5'}>
        <Center pt={'10vh'}>
          <Heading
          fontFamily= 'Roboto'
          fontStyle= 'normal'
          fontWeight= '900'
          fontSize= '64'
          lineHeight= '75'
          color='#000000'
          >
            Sevicios Lorem ipsum dolor sit amet
          </Heading>
        </Center>
        <HStack flex={1} pt={'10vh'} justifyContent={'space-evenly'} bg={'#E5E5E5'}>
          <Box bg={'#FFFFFF'} 
          maxW={'33vh'}
          p={5}
          shadow={5}
          mt={3}
          >
            <Text>
              Lorem ipsum dolor sit amet, 
              consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt 
              ut labore et dolore magna aliqua. 
            </Text>
          </Box>
          <Box bg={'#FFFFFF'} 
          maxW={'33vh'}
          p={5}
          shadow={5}
          mt={3}>
            <Text>
              Lorem ipsum dolor sit amet, 
              consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt 
              ut labore et dolore magna aliqua. 
            </Text>
          </Box>
          <Box bg={'#FFFFFF'} 
          maxW={'33vh'}
          p={5}
          shadow={5}
          mt={3}>
            <Text>
              Lorem ipsum dolor sit amet, 
              consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt 
              ut labore et dolore magna aliqua. 
            </Text>
          </Box>
        </HStack>
      </Box>
      <Box bg={'#E5E5E5'} flex={1} justifyContent={'space-between'} py={10}>
        <HStack flex={1} justifyContent={'space-between'} mx={40}>
          <VStack flex={1} >
            <div style={{transform: 'skew(-15deg)', background: '#E67008', maxWidth:'20vh'}}>
              <Center h={20}>
                <TodoSvg></TodoSvg> 
              </Center> 
            </div>
            <Box >
              <Text maxW={'35vh'} pt={10} mx={2}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Praesentium facilis consectetur atque, magnam beatae, eligendi impedit rerum officiis deleniti expedita nemo iste, repellat eos labore fugit maiores totam. Totam, iure!
              </Text>
            </Box>
          </VStack>
          <VStack flex={1} >
            <div style={{transform: 'skew(-15deg)', background: '#14558F', maxWidth:'20vh'}}>
              <Center h={20}>
                <ClockSvg></ClockSvg>
              </Center>
            </div>
            <Text maxW={'35vh'} pt={10} mx={2}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque at maiores nemo perspiciatis doloribus necessitatibus amet excepturi ab non iusto sunt dolorum veritatis, veniam similique, soluta nobis optio quidem blanditiis?
            </Text>
          </VStack>
          <VStack flex={1} >
            <div style={{transform: 'skew(-15deg)', background: '#91BF26', maxWidth:'20vh'}}>
              <Center h={20}>
                <TruckSvg></TruckSvg>
              </Center>
            </div>
            <Text  maxW={'35vh'} pt={10} mx={2}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente tempore tempora suscipit nihil vitae asperiores repudiandae quidem. Cum molestias eos reiciendis mollitia ut accusantium eveniet aliquam maxime? Dolorum, vero ullam.
            </Text>
          </VStack>
          <VStack flex={1} >
            <div style={{transform: 'skew(-15deg)', background: '#ffff', maxWidth:'20vh'}}>
              <Center h={20}>
                <SearchSvg></SearchSvg>
              </Center>
            </div>
            <Text  maxW={'35vh'} pt={10} mx={2}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente tempore tempora suscipit nihil vitae asperiores repudiandae quidem. Cum molestias eos reiciendis mollitia ut accusantium eveniet aliquam maxime? Dolorum, vero ullam.
            </Text>
          </VStack>
        </HStack>
      </Box>  
      <Footer />
    </>
  );
};

export default ServiceScreen;