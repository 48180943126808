const TodoSvg = () => {
  return ( 
    <svg width="69" height="74" viewBox="0 0 69 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M54.6825 9.33008H1.26046C0.747524 9.33008 0.311523 9.76573 0.311523 10.2782V72.7033C0.311523 73.2158 0.747524 73.6514 1.26046 73.6514H54.6825C55.1954 73.6514 55.6314 73.2158 55.6314 72.7033V10.2782C55.6314 9.76573 55.221 9.33008 54.6825 9.33008Z" fill="#013177"/>
      <path d="M48.7582 14.2246H7.92866C7.41573 14.2246 6.97974 14.6602 6.97974 15.1728V64.9385C6.97974 65.451 7.41573 65.8867 7.92866 65.8867H48.7325C49.2454 65.8867 49.6814 65.451 49.6814 64.9385V15.1728C49.7071 14.6602 49.2711 14.2246 48.7582 14.2246Z" fill="white"/>
      <path d="M43.8853 6.43428H34.7551C34.2935 3.12852 31.421 0.565918 27.9844 0.565918C24.5477 0.565918 21.6753 3.12852 21.2137 6.43428H12.0578C11.5449 6.43428 11.1089 6.86992 11.1089 7.38244V17.4791C11.1089 17.9916 11.5449 18.4273 12.0578 18.4273H43.8853C44.3983 18.4273 44.8343 17.9916 44.8343 17.4791V7.38244C44.8343 6.8443 44.4239 6.43428 43.8853 6.43428Z" fill="#074EA3"/>
      <path d="M17.4435 24.5006C17.4435 25.91 16.2894 27.0376 14.9045 27.0376C13.4939 27.0376 12.3655 25.8844 12.3655 24.5006C12.3655 23.1168 13.5196 21.9636 14.9045 21.9636C16.2894 21.9636 17.4435 23.0912 17.4435 24.5006Z" fill="#FBB040"/>
      <path d="M42.449 25.4488H21.8291C21.3161 25.4488 20.8801 25.0132 20.8801 24.5007C20.8801 23.9881 21.3161 23.5525 21.8291 23.5525H42.449C42.9619 23.5525 43.3979 23.9881 43.3979 24.5007C43.3979 25.0132 42.9876 25.4488 42.449 25.4488Z" fill="#013177"/>
      <path d="M17.4435 32.7522C17.4435 34.1616 16.2894 35.2892 14.9045 35.2892C13.4939 35.2892 12.3655 34.1616 12.3655 32.7522C12.3655 31.3428 13.5196 30.2152 14.9045 30.2152C16.2894 30.2152 17.4435 31.3428 17.4435 32.7522Z" fill="#FBB040"/>
      <path d="M42.449 33.7004H21.8291C21.3161 33.7004 20.8801 33.2648 20.8801 32.7522C20.8801 32.2397 21.3161 31.8041 21.8291 31.8041H42.449C42.9619 31.8041 43.3979 32.2397 43.3979 32.7522C43.3979 33.2648 42.9876 33.7004 42.449 33.7004Z" fill="#013177"/>
      <path d="M17.4435 41.0038C17.4435 42.4132 16.2894 43.5408 14.9045 43.5408C13.4939 43.5408 12.3655 42.4132 12.3655 41.0038C12.3655 39.5943 13.5196 38.4668 14.9045 38.4668C16.2894 38.4668 17.4435 39.5943 17.4435 41.0038Z" fill="#FBB040"/>
      <path d="M42.449 41.952H21.8291C21.3161 41.952 20.8801 41.5163 20.8801 41.0038C20.8801 40.4913 21.3161 40.0557 21.8291 40.0557H42.449C42.9619 40.0557 43.3979 40.4913 43.3979 41.0038C43.3979 41.5163 42.9876 41.952 42.449 41.952Z" fill="#013177"/>
      <path d="M17.4435 49.2554C17.4435 50.6648 16.2894 51.7923 14.9045 51.7923C13.4939 51.7923 12.3655 50.6648 12.3655 49.2554C12.3655 47.8459 13.5196 46.7184 14.9045 46.7184C16.2894 46.7184 17.4435 47.8459 17.4435 49.2554Z" fill="#FBB040"/>
      <path d="M42.449 50.2036H21.8291C21.3161 50.2036 20.8801 49.7679 20.8801 49.2554C20.8801 48.7429 21.3161 48.3073 21.8291 48.3073H42.449C42.9619 48.3073 43.3979 48.7429 43.3979 49.2554C43.3979 49.7679 42.9876 50.2036 42.449 50.2036Z" fill="#013177"/>
      <path d="M53.8875 39.62C53.8362 39.3638 53.7079 39.1587 53.5027 39.005L49.7583 36.4424C49.3223 36.1349 48.7325 36.263 48.4503 36.673L37.6531 52.3049C37.4992 52.5099 37.4479 52.7662 37.4992 53.0224C37.5505 53.2787 37.6787 53.4837 37.8839 53.6375L41.6283 56.2257C41.7822 56.3282 41.9874 56.4051 42.1669 56.4051C42.4747 56.4051 42.7568 56.2513 42.9363 55.9951L53.7336 40.3632C53.8618 40.1325 53.9387 39.8763 53.8875 39.62Z" fill="#FBB040"/>
      <path d="M66.4543 16.2491C65.5566 15.6341 64.4538 15.4034 63.3767 15.5828C62.2995 15.7878 61.3506 16.3772 60.7351 17.2998L47.7066 36.1862C47.5527 36.3912 47.5014 36.6474 47.5527 36.9037C47.604 37.1599 47.7322 37.3649 47.9374 37.5187L53.1437 41.1063C53.2975 41.2089 53.5027 41.2857 53.6822 41.2857C53.99 41.2857 54.2721 41.132 54.4516 40.8757L67.4801 21.9893C68.7881 20.093 68.3008 17.5304 66.4543 16.2491Z" fill="#F7941E"/>
      <path d="M61.8379 35.5455C61.6584 35.5455 61.4532 35.4943 61.2993 35.3661C60.8633 35.0586 60.7608 34.4692 61.0685 34.0592L66.8903 25.6282L65.4798 22.3481C65.2746 21.8612 65.5054 21.3231 65.967 21.0924C66.4543 20.8874 66.9929 21.0924 67.2237 21.5793L68.8651 25.3464C68.9933 25.6539 68.9677 25.987 68.7625 26.2689L62.6586 35.1099C62.4278 35.4174 62.12 35.5455 61.8379 35.5455Z" fill="#F7941E"/>
      <path d="M37.6273 52.3306L34.6523 62.3247L42.9362 55.9951L37.6273 52.3306Z" fill="#F7941E"/>
    </svg>
  )}
  
  export default TodoSvg;