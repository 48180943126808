import i18next from '../i18n';
export const paragraphs = [
    {type: 'title', text: i18next.t('Privacy Policy')},
    {type: 'text', text: i18next.t('security_1')},
    {type: 'text', text: i18next.t('security_2')},
    {type: 'text', text: i18next.t('security_3')},
    {type: 'title', text: i18next.t('security_4')},
    {type: 'text', text: i18next.t('security_5')},
    {type: 'text', text: i18next.t('security_6')},
    {type: 'title', text: i18next.t('security_7')},
    {type: 'text', text: i18next.t('security_8')},
    {type: 'text', text: i18next.t('security_9')},
    {type: 'text', text: i18next.t('security_10')},
    {type: 'text', text: i18next.t('security_11')},
    {type: 'text', text: i18next.t('security_12')},
    {type: 'title', text: i18next.t('security_13')},
    {type: 'text', text: i18next.t('security_14')},
    {type: 'text', text: i18next.t('security_15')},
    {type: 'title', text: i18next.t('security_16')},
    {type: 'text', text: i18next.t('security_17')},
    {type: 'title', text: i18next.t('security_18')},
    {type: 'text', text: i18next.t('security_19')},
    {type: 'text', text: i18next.t('security_20')},
    {type: 'text', text: i18next.t('security_21')},
    {type: 'title', text: i18next.t('security_22')},
    {type: 'text', text: i18next.t('security_23')},
    {type: 'text', text: i18next.t('security_24')},
    {type: 'text', text: i18next.t('security_25')},
    {type: 'text', text: i18next.t('security_26')},
    {type: 'text', text: i18next.t('security_27')},
    {type: 'text', text: i18next.t('security_28')},
    {type: 'text', text: i18next.t('security_29')},
    {type: 'text', text: i18next.t('security_30')},
    {type: 'text', text: i18next.t('security_31')},
    {type: 'text', text: i18next.t('security_32')},
    {type: 'text', text: i18next.t('security_33')},
    {type: 'title', text: i18next.t('security_34')},
    {type: 'text', text: i18next.t('security_35')},
    {type: 'text', text: i18next.t('security_36')},
    {type: 'text', text: i18next.t('security_37')},
    {type: 'text', text: i18next.t('security_38')},
    {type: 'text', text: i18next.t('security_39')},
    {type: 'text', text: i18next.t('security_40')},
    {type: 'text', text: i18next.t('security_41')},
    {type: 'text', text: i18next.t('security_42')},
    {type: 'text', text: i18next.t('security_43')},
    {type: 'text', text: i18next.t('security_44')},
    {type: 'title', text: i18next.t('security_45')},
    {type: 'text', text: i18next.t('security_46')},
    {type: 'title', text: i18next.t('security_47')},
    {type: 'text', text: i18next.t('security_48')},
    {type: 'title', text: i18next.t('security_49')},
    {type: 'text', text: i18next.t('security_50')},
    {type: 'text', text: i18next.t('security_51')},
    {type: 'title', text: i18next.t('security_52')},
    {type: 'text', text: i18next.t('security_53')},
    {type: 'title', text: i18next.t('security_54')},
    {type: 'text', text: i18next.t('security_55')},
    {type: 'text', text: i18next.t('security_56')},
    {type: 'text', text: i18next.t('security_57')},
    {type: 'text', text: i18next.t('security_58')},
    {type: 'text', text: i18next.t('security_59')},
    {type: 'text', text: i18next.t('security_60')},
    {type: 'text', text: i18next.t('security_61')},
    {type: 'text', text: i18next.t('security_62')},
    {type: 'text', text: i18next.t('security_63')},
    {type: 'text', text: i18next.t('security_64')},
    {type: 'title', text: i18next.t('security_65')},
    {type: 'text', text: i18next.t('security_66')},
    {type: 'text', text: i18next.t('security_67')},
    {type: 'text', text: i18next.t('security_68')},
    {type: 'title', text: i18next.t('security_69')},
    {type: 'text', text: i18next.t('security_70')},
    {type: 'text', text: i18next.t('security_71')},
    {type: 'text', text: i18next.t('security_72')},
    {type: 'text', text: i18next.t('security_73')},
    {type: 'text', text: i18next.t('security_74')},
    {type: 'text', text: i18next.t('security_75')},
    {type: 'text', text: i18next.t('security_76')},
    {type: 'text', text: i18next.t('security_77')},
    {type: 'text', text: i18next.t('security_78')},
    {type: 'text', text: i18next.t('security_79')},
    {type: 'text', text: i18next.t('security_80')},
    {type: 'text', text: i18next.t('security_81')},
 ];