import { Text, Skeleton, ITextProps, HStack, Image } from 'native-base';

type CustomTextSkeletonProps = {
  placeholderText: string;
  text: string;
  loading: boolean;
  flag?: boolean
} & ITextProps;

const CustomTextSkeleton: React.FC<CustomTextSkeletonProps> = ({
  loading,
  placeholderText,
  text,
  flag,
  ...textProps
}) => {
  if (loading) {
    return (
      <Skeleton.Text {...textProps} isLoaded={false}>
        {placeholderText}
      </Skeleton.Text>
    );
  }

  const getCountry = (country:string): string => {
    if(country === 'Colombia') {
      return 'COL'
    }
    if (country === 'Peru') {
      return 'PR'
    }
    if (country === 'Argentina' ) {
      return 'AR'
    }

    return country;
  }

  return<HStack>
    {flag && (
      <Image mt={1} mr={1} source={{ uri: `${process.env.PUBLIC_URL}/icons/${text.toLowerCase()}.svg`, }} alt={`${text}`} width={5} height={4} />
    )}
      <Text {...textProps}>{getCountry(text)}</Text>
    </HStack>
  ;
};

export default CustomTextSkeleton;
