import { Box, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const HelpCenter = () => {
  const { t } = useTranslation();
    return (
        <div id="wave" className="wave_container">
            <div className="landing_gradient" style={{ zIndex: 3, height: '200px' }}>
                <Center height="329px">
                    <Text color="#ffffff" fontSize="35px" bold fontFamily="Segoe UI" fontStyle="italic">{t("contact_03")}</Text>
                    <Text mt="20px" mb="30px" color="#ffffff" width="529px" fontSize="20px" fontFamily="Segoe UI" textAlign="center">{t("contact_04")}</Text>
                    <Pressable>
                        {({
                            isHovered,
                        }) => {
                            return <Link to="/help"><Box width="206px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} borderRadius="23px" height="47px" display="flex" flexDir="row" justifyContent="center" alignItems="center" >
                                <Text color="#293d8b" textTransform="uppercase" fontSize="16px" fontWeight="bold" fontFamily="Segoe UI">{t("help_center")}</Text>
                            </Box></Link>;
                        }}
                    </Pressable>
                </Center>
            </div>
        </div>);
}