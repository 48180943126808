import { Box, Center, Heading, HStack, Image, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { SearchInput } from "../components/SearchInput";
import { paragraphs } from "../helper/help.helper";

export const HelpScreen = () => {
    const { t } = useTranslation();
    return <Box>
        <div className="landing_container landing_gradient" style={{ zIndex: 3, height: '482px' }}>
            <Navbar upper={true} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
                <div style={{ width: "194px", height: "194px", position: 'relative', marginLeft: '100px', marginTop: "45px" }}>
                    <Image
                        width={'194px'}
                        height={'194px'}
                        style={{ position: 'absolute' }}
                        resizeMode={'cover'}
                        source={{
                            uri: `${process.env.PUBLIC_URL}/icons/help/help_1.svg`,
                        }}
                    />
                </div>

                <Box width="40%">
                    <Text color="#ffb31c" textAlign="center" fontStyle="italic" fontWeight={800} fontFamily="Segoe UI" fontSize={window.innerWidth < 1920 ? "53px" : "60px"}>{t('hy')}</Text>
                    <Text color="#ffffff" textAlign="center" bold fontStyle="italic" fontSize={window.innerWidth < 1920 ? "43px" : "50px"} fontFamily="Segoe UI" mb="20px">{t('how_can_we_help_you')}</Text>
                    <Text color="#ffffff" textAlign="center" fontSize="20px" fontFamily="Segoe UI" mb="20px">{t('search_in_our')}</Text>
                    <SearchInput onlyArrow />
                </Box>
                <div style={{ width: "194px", height: "194px", position: 'relative', marginRight: '100px', marginTop: "45px" }}>
                    <Image
                        width={'194px'}
                        height={'194px'}
                        style={{ position: 'absolute' }}
                        resizeMode={'cover'}
                        source={{
                            uri: `${process.env.PUBLIC_URL}/icons/help/help_2.svg`,
                        }}
                    />
                </div>
            </div>
        </div>
        <VStack mx={"auto"} width="60%" alignItems="center" mt={30} mb={30}>
            <Text color="#293d8b" bold fontSize={25}>{t('faq')}</Text>
            <Box width={100} height="3px" backgroundColor="#ff9300" mt="10px" />
        </VStack>
        <HStack maxWidth="60%" mx={'auto'} flexWrap="wrap">
            {paragraphs.filter(el => el.type !== 'text').map((text, i) => {
                return (
                    <div id={text.text} style={{ width: '50%', marginBottom: "20px" }}>
                        <Link to={`/help/question/${i + 1}`} style={{ textDecoration: 'none' }}>
                            <Pressable p={0} m={0}>
                                {({ isHovered }) => {
                                    return (
                                        <HStack w="90%">
                                        <Text
                                            color={'#1264A3'}
                                            fontSize="18px"
                                            fontFamily="Segoe UI"
                                            fontWeight="semibold"
                                            textDecorationLine={ isHovered ? 'underline' : "none"}
                                            key={i * 100}
                                            mb={text.type === 'text' ? 12 : 2}
                                            textAlign="left">
                                            {`${i + 1}. `}
                                        </Text>
                                        <Text
                                            color={'#1264A3'}
                                            fontSize="18px"
                                            fontFamily="Segoe UI"
                                            fontWeight="semibold"
                                            textDecorationLine={ isHovered ? 'underline' : "none"}
                                            key={i}
                                            mb={text.type === 'text' ? 12 : 2}
                                            textAlign="left">
                                            {text.text.split(`${i+1}. `)[1]}
                                        </Text>
                                        </HStack>
                                    )
                                }}</Pressable>
                        </Link>
                    </div>
                );
            })}
        </HStack>
        <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
            <Footer />
        </Box>
    </Box>
}