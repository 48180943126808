export const translation = {
    "landing_text": "Get Courier and Postal Services Tracking Information",
    "landing_header": "The leading package tracking solution in Latin America",
    "query": 'Query',
    "about Us": 'About Us',
    "contact us": 'Contact Us',
    "get_in_touch": "Get in contact with us",
    "get_in_touch_description": "The leading platform for integrated logistics information services in Latin America, customizes information flow services for logistics and express delivery in Latin America. For more personalized services or suggestions, contact us",
    "contact": "CONTACT",
    "help_center": 'Help center',
    "security policy": 'Security Policy',
    "Provide smooth query": 'Provide smooth query',
    "content_1": 'Automatically identify all cooperative transporters, track logistics information throughout the process, without selecting a logistics provider, complete and accurate trajectory information can be obtained in one step, and all nodes are clearly presented.',
    "Focus on the Latin American market": 'Focus on the Latin American market',
    "content_2": 'It has cooperated with mainstream logistics providers in Latin America, and the cooperative transporters cover mainstream countries in Latin America. If you need to track more parcels from carriers, you can give feedback to customer service, and we will try our best to support the carriers you need.',
    "Customer service across time differences": 'Customer service across time differences',
    "content_3": 'Has a professional multilingual customer service and Chinese and foreign technical research and development team, dedicated to quickly solving problems for users, providing users with 6*24 hours of online service.',
    "User privacy data protection": 'User privacy data protection',
    "content_4": 'We look forward to maintaining a healthy and long-term cooperative relationship with e-commerce platforms, sellers and logistics providers. Comply with the General Data Protection Regulation to protect user data and privacy.',
    "advantage": 'Advantage',
    "world_text_1": `Latin America's leading logistics digital service and management platform,`,
    "world_text_2": 'Committed to building more independent and smarter logistics information solutions.',
    "agreement": 'Agreement',
    "copyright": 'Copyright Notice',
    "Privacy Policy": 'Privacy Policy',
    "Feedback": 'Agreement',
    "contact_us_banner_text": 'The leading logistics information integrated service platform in Latin America, customizes the information flow service of Latin America logistics and express delivery for you. For more customized services or suggestions, please contact us',
    "Enter tracking number": 'Enter tracking number',
    "about_1": 'track.a2a56.com provides practical freight tracking services and logistics flow data services for the majority of e-commerce enterprises.',
    "about_2": 'Founded in Mexico, it focuses on e-commerce and cross-border logistics for the Latin American market.',
    "about_3": 'At track.a2a56.com, we believe that tracking information is the key to attracting customers, and the best way to improve the experience is to provide tracking information in one place without having to actively choose the Carriers site again. We believe that access to our website information applies to all e-commerce participants, whether you are a buyer, logistics provider or seller.',
    "about_4": `We hope you enjoy using track.a2a56.com's package tracking service and we are happy to give you the best experience possible. If you have any questions or comments, please don't hesitate to contact us!`,
    "security_1": 'Thank you for visiting the track.a2a56.com website, mobile applications, devices and other related software. This policy applies to the provision of track.a2a56.com products and services (including mobile terminals, websites, mini programs, etc.), hereinafter referred to as "we" or "track.a2a56.com". We respect and take your online privacy very seriously and hope you understand our privacy policy to protect your personal information. This Privacy Policy also applies to all of our services and products, whether through the track.a2a56.com main site, mobile app or mobile website.',
    "security_2": 'The privacy policy is explained as follows:',
    "security_3": 'If you continue to visit our website, you accept the terms contained in this Privacy Policy:',
    "security_4": 'definition',
    "security_5": 'Personal data: means any information about a natural person that can be directly or indirectly identified, in particular by reference to identifiers such as names, identification numbers, location data or online identifiers.',
    "security_6": 'Processing: means any operation performed on personal data such as collection, recording, organization, structuring, storage, adaptation or any type of disclosure or other use.',
    "security_7": 'Register with our service system',
    "security_8": 'Our website provides the option to obtain the newsletter. To use this service, you must set up an account. With regard to the registration of an account and its subsequent use, we process:',
    "security_9": 'Información proporcionada por el registro (como su nombre y dirección de correo electrónico) Información relacionada con las funciones de inicio de sesión de la cuenta (como detalles de inicio de sesión y contraseña) Información que envía (como por correo electrónico o formularios de información del sitio web)',
    "security_10": 'We will process the personal data you provide:',
    "security_11": 'Confirm your identity when you log in\nProvide you with the services and information you request through the website\nManage your account\nCommunicate with you\nAdvertising and analytics',
    "security_12": 'Your personal data will be retained for as long as your user account is used without exception in the following specific services. After deleting your account, your personal data will be blocked and deleted at the next database cleanup. Statutory storage obligations or possible legal action due to misconduct in the Services or payment issues may result in your personal data being retained for a longer period of time. In this case, we will notify you accordingly.',
    "security_13": 'Communication',
    "security_14": 'Through your email address you can subscribe to our newsletter and if you agree to receive such newsletters you can subscribe to our newsletter which provides you with the latest updates about your package and may also Contains topic-specific ads.',
    "security_15": `If you don't have an account before, you can also complete your registration during the newsletter ordering. You may opt out of the Service via the link provided in each press release.`,
    "security_16": 'Online Advertising',
    "security_17": `This website uses an online advertising service to show you advertisements that match your interests. The corresponding ad is marked with the provider's name, eg "Google-Ad". In addition, this service helps us improve our website and make your visit more interesting. For this purpose, we collect statistical information about you, which is processed by the provider.`,
    "security_18": 'Google Adword',
    "security_19": 'We use Google Adwords to advertise on our website and third-party websites to previous visitors to our website. Google Ads are provided by Google LLC, 1600 Theater Parkway Mountain View, CA 94043, USA ("Google").',
    "security_20": `Ads are displayed based on information stored in cookies that Google places on your computer. Text files include information about your visit to our website, in particular the product views read out for specific product recommendations during subsequent visits to this or third-party websites. The cookie contains a random alias. If you visit our website and view our products at a certain time, Google can recognize you by aliases. We or Google will not combine this information with your personal data and will not disclose your personal data to any third parties. You can prevent the storage and use of information in cookies placed by Google by clicking on the following link https://www.google.com/ads/preferences and configuring your preferences.`,
    "security_21":`For more information on Google's Privacy Policy, please see https://privacy.google.com/ and https://www.google.com/policies/privacy/.`,
    "security_22":'Google Ads and Facebook Custom Audiences',
    "security_23":'On this website, we use the remarketing or "similar audiences" feature of AdWords, provided by Google Inc., 1600 Theater Parkway Mountain View, CA 94043, USA, hereinafter referred to as "Google".',
    "security_24":'Google uses cookies/tracking technologies, which are text files stored on your computer, that allow us to analyze your use of our website. The information generated by the cookie/tracking technology about your use of this website (including your IP address) will be transmitted to and stored on a Google server in the United States. After that, Google will delete the last three digits of this IP address, so that this IP address can no longer be unambiguously matched with personal data. Google is Privacy Shield certified and agrees to abide by the EU-US Privacy Shield Treaty published by the US Department of Commerce regarding the collection, use, and storage of personal data from EU member states. Google will use this information to analyse your use of the website, to create reports on website activity for website operators and to provide other services related to the use of the website and the Internet. In addition, if required by law',
    "security_25": `Google may transfer this information to third parties if it is required by law or if the third party processes data on Google's behalf.`,
    "security_26":`Third-party providers, including Google, serve advertisements on websites on the Internet. Third-party providers (including Google) use stored cookies/tracking information to serve advertisements based on users' previous visits to this website.`,
    "security_27":'Para obtener información adicional sobre el análisis anónimo del comportamiento de búsqueda, consulte:',
    "security_28":'https://support.google.com/analytics/answer/6004245?hl=en',
    "security_29":'https://www.google.com/policies/technologies/ads/',
    "security_30":'You can object at any time to the collection and storage of data for remarketing purposes - this objection will be valid in the future, but not retroactively - by deactivating interest-based advertising in Google or deactivating services in the website Network Advertising Initiative. Note: In this case, you may no longer be able to use all functions of this website. By using this website, you agree to the processing of the data collected by Google about you in the manner and for the purposes described above.',
    "security_31":'In addition, this website uses retargeting tags and custom audiences from Facebook Inc., 1601 Southern California Avenue, Palo Alto, CA 94304, USA, hereinafter referred to as "Facebook".',
    "security_32":`When you visit our web pages, the remarketing tag will establish a direct connection between your browser and Facebook's servers. In this way, Facebook will know that you have visited our pages using your IP address. This will enable Facebook to match your visits to our pages with your user account. Information obtained in this way can be used to display Facebook ads. Please note that, as the provider of the web page, we do not receive any information about the content of the data transmitted by Facebook and their use.`,
    "security_33":`Regarding the use of Custom Audiences, we would like to point out that Facebook and third parties use cookies, web beacons or similar technologies to collect or collect information on this website. Based on the data we obtain, we can improve the efficiency of our Facebook campaigns, for example, by arranging to show content or advertisements only to website visitors. The data collected in this way will be encrypted and transmitted to Facebook and will be anonymous to us, for example, we cannot see the personal data of individual users.`,
    "security_34":'Third-Party Content and Social Media Plugins',
    "security_35": `We use the following social media plugins: Facebook. This allows you to communicate with such services and like or comment on our website. Social media plug-ins support direct communication between your device and the social media provider's server, allowing the social media provider to communicate with you and collect information about your browsing of our website. This processing is based on Article 6 1b of the Data Protection Regulation. represent our legitimate interests in improving your website experience and optimizing our services.`,
    "security_36":'The transfer of personal data occurs whether or not you have an account with the provider.',
    "security_37":'Please note that we neither control the scope of the personal data collected by the respective plugin provider nor know the purpose of the processing or the period for which your personal data will be retained.',
    "security_38":`For more information about the providers' processing of your personal data in the course of their operations, please refer to their respective privacy policies. Additionally, you will receive further information about your rights and privacy settings.`,
    "security_39":'a) Facebook social plugin',
    "security_40":`We use so-called social plug-ins ("plug-ins") on the social networking site facebook.com provided by Facebook, Inc., 1601 Southern California Avenue, Palo Alto, CA 94304, USA ("Facebook"). Plugins can be identified by one of Facebook's logos (a white "f" on a blue tile or a "Like" mark) or the additional text "Facebook Social Plugins". A list of Facebook social plugins and their appearance can be accessed at: https://developers.facebook.com/docs/plugins/.`,
    "security_41": `If a user visits one of these websites using such a plug-in, the user's browser connects directly to Facebook's servers. The plugin and its content are served directly on Facebook's servers and on the website of the user's browser.`,
    "security_42": `The reasons and scope of data collection, as well as information on the data processed and used by Facebook, as well as the rights of users in this regard and the setting options to protect the privacy of users, please refer to the following options: https://www.facebook.com/policy. php.`,
    "security_43": `If a user is a member of Facebook and does not want Facebook to collect personal data through this Page and link this data with data stored on Facebook, the user needs to log out of Facebook before entering this Page.`,
    "security_44": `Users can also block Facebook's plug-ins using an add-on for the user's browser (eg "Facebook Blocker").`,
    "security_45": `Online privacy and safety for minors`,
    "security_46": `We respect the privacy of minors very much. We do not knowingly and knowingly collect personal information from minors under the age of 18. If you continue to visit or use the services of this site, it means that you guarantee that you have reached the age of 18 or use the services of this site under the supervision of a parent or guardian. \nIf you are under the age of 18, please do not submit any personal information to us and rely on a parent or guardian to assist you in using this website.`,
    "security_47": `Supplementary terms for visitors from EU countries`,
    "security_48": `This part of this Privacy Policy is only applicable to visitors from EU member states who use our services and products, and only serves as a supplement to this Privacy Policy. \nSubject to applicable laws, you have the right to object to or request the restriction of your processing of your personal information, and to request access, porting, correction and deletion of your personal information. The use of your information is based on your consent, which you may also withdraw at any time without affecting the lawfulness of processing based on consent prior to its withdrawal. \nPlease contact us via our email track.a2a56.com to submit a request.`,
    "security_49": `Safety`,
    "security_50": `We have reasonable security measures in place to prevent the loss, misuse and alteration of personal data under our control. For example, our security and privacy policies are regularly reviewed and enhanced as needed, and only authorized personnel have access to personal data. While we cannot ensure or warrant that information will never be lost, misused or altered, we will take all reasonable efforts to prevent loss, misuse or alteration of information.`,
    "security_51": `You should remember that submitting information over the Internet is never completely secure. We cannot guarantee the security of information you transmit over the Internet, and any such submissions are at your own risk.`,
    "security_52": `Data retention`,
    "security_53": `We strive to keep our processing of your personal data as limited as possible. If there is no specific retention period specified in this policy, your personal data will only be retained for as long as we need to fulfill the purpose for which we collected it, and if applicable, for as long as required by statutory retention requirements.`,
    "security_54": `Your rights`,
    "security_55": `Under the laws that apply to you, you may have the right to exercise some or all of the following rights:`,
    "security_56": `To request (i) information about whether your personal data is retained and (ii) access to and/or copying of your retained personal data, including the purpose of processing, categories of relevant personal data, data recipients and potential retention Expect;`,
    "security_57": `Request correction, deletion or restriction of your personal data, such as: (i) the personal data is incomplete or inaccurate, (ii) it is no longer necessary for the purpose for which the personal data was collected, or (iii) the basis for approval is revoked;`,
    "security_58": `Refuse to provide without prejudice to the data processing activities carried out prior to the withdrawal, and withdraw your approval decision regarding the processing of your personal data at any time;`,
    "security_59": `Object to the processing of your personal data on the basis of your specific circumstances. In this case, please provide us with information about your specific situation. After evaluating the facts provided by you, we will stop processing your personal data or provide you with compelling legitimate grounds for continued processing;`,
    "security_60": `For any potential infringement of the processing of your personal data, please contact us:`,
    "security_61": `Requirements: i) to receive the personal data about you that you have provided to us in a structured, commonly used and machine-readable format, and (ii) to transmit such data unhindered from our side to other controllers; in where technically feasible, you have the right to transfer personal data directly from us to another controller;`,
    "security_62": `Not subject to any automated decisions, including profiling (processing automated decisions based on automated processing of data for the purposes of evaluation), which have legal effects on you or have similar effects on you.`,
    "security_63": `You can exercise the above rights or ask us any questions by contacting us using info@a2a56.com.`,
    "security_64": `We reserve the right to revise this policy by updating our website from time to time. Please visit the website regularly and review our respective current privacy policies. This policy was last updated in January 2021.`,
    "security_65": `Your choice`,
    "security_66": `You can always choose not to provide your personal information, although certain features of our website may not be available.`,
    "security_67": `You can request by emailing info@a2a56.com:`,
    "security_68": `• Correct or update your personal information\n• Stop receiving emails to your email address\n• Stop receiving text messages to your mobile phone\n• Deactivate your account\n• Block cookies on your computer, which or prevents you from using certain services on the website`,
    "security_69": `Cookies Policy`,
    "security_70": `#About cookies`,
    "security_71": `A cookie is a file sent by a web server to a web browser, usually including a unique identifier, that can be sent back to the server each time the browser requests a page from the server.`,
    "security_72": `Web servers may use cookies to identify and track users as they navigate different pages on the website, and to identify users who return to the website.`,
    "security_73": `Cookies can be "persistent" cookies or "session" cookies. A persistent cookie consists of a text file sent by a web server to a web browser, which will be stored by the browser and remain valid until its set expiration date (unless deleted by the user before the expiration date). On the other hand, session cookies will expire when the user's session ends (when the web browser is closed).`,
    "security_74": `track.a2a56.com uses cookies and similar technologies to analyze trends, administer the site, track user activity on the site, and gather demographic information about our entire user base. We use cookies for a variety of purposes, including but not limited to identifying users, for session tracking, preference data stored on the user's local terminal and web pages visited.`,
    "security_75": `#Third party cookies`,
    "security_76": `You may also receive third-party cookies when you use this website.`,
    "security_77": `Our advertisers and service providers may send you cookies. They may use the information obtained from your use of cookies:`,
    "security_78": `Track your browser across multiple websites\ntarget ads that may be of particular interest to you.`,
    "security_79": `#Block cookies`,
    "security_80": `Most browsers allow you to refuse to accept cookies. E.g:`,
    "security_81": `You can lower the cookie usage level in your browser. If you choose to disable cookies, your use of certain features of this website may be limited.`,
    "An error occurred while getting the data from the server": `An error occurred while getting the data from the server.`,
    "help_1": `1. How long will it take to receive my package?`,
    "help_2": `The following is the normal shipping time of the global postal registered small package or E-mail (note: remote countries and regions are not included):`,
    "help_3": `     • Day 1-2 Receive/Original Shipment`,
    "help_4": `     • Day 2-3 Export Operations Center`,
    "help_5": `     • Day 2-4 Awaiting Customs Inspection/Export Operations Center`,
    "help_6": `After the flight/ship where the package is located leaves the port, most of the package status will not be updated until it reaches the destination country!`,
    "help_7": `     • Day 4-10 Arrival at destination country port or airport`,
    "help_8": `     • Day 10-15 Awaiting Customs Inspection/Import Operations Center`,
    "help_9": `     • Days 15-30 destination domestic shipping and last mile delivery`,
    "help_10": `If the package is still not delivered after 60 days, the package may be returned to the sender, please contact the carrier for confirmation.`,
    "help_11": `When your package arrives at the destination country, if you are in a hurry to get your package, you can try to contact your local carrier for active pickup.`,
    "help_12": `2. Where is my package now?`,
    "help_13": `For most international registered small, large and express services, there is generally no estimated arrival date for international shipments, so the transit time may be slower or faster than you expected. Due to the differences in import and export customs procedures and airlines in the destination country, international shipping is very different from domestic express delivery.`,
    "help_14": `track.a2a56.com is a third-party independent site that focuses on tracking postal parcels in the Latin American market and international express parcels. We mainly provide you with tracking data legally obtained from the official website of the corresponding partner carrier. We are not involved in the direct package shipping and online transaction process of your products.`,
    "help_15": `If the carrier updates the logistics information, track.a2a56.com will also be updated, you can try to check your package after a while.`,
    "help_16": `3. Why is my package "unavailable"?`,
    "help_17": `"Unable to find" means that we could not find any information about the package corresponding to the tracking number. We recommend that you double check that the tracking number is correct, or contact the shipper (the store where you placed the order) to verify your tracking number.`,
    "help_18": `If the tracking number is correct, please check the details for tracking it at least 1-2 days after the package has been dispatched. Because usually, the carrier also needs some time to collect and process the package.`,
    "help_19": `4. I did not receive my package, can I apply for a refund?`,
    "help_20": `If you have any refund needs, we recommend that you contact the merchant/shipper who purchased the item directly.`,
    "help_21": `track.a2a56.com is a third-party independent site. We only provide parcel services for global postal parcels and international express delivery. It is important to note that we are not directly involved in specific shipping matters, nor are we involved in online transactions.`,
    "help_22": `Therefore, issues related to online transactions or refunds can only be handled by the store where you purchased the item.`,
    "help_23": `5. How to change the delivery address of the package?`,
    "help_24": `In general, if the package has been shipped, you will need to contact the local shipping company immediately or report your request for a change of address to the store from which you purchased the item. Or you can also visit the official website of the carrier for further assistance.`,
    "help_25": `6. Will there be any updates when the package is in a certain region?`,
    "help_26": `If your package has not been updated with logistics information in a long time, it may mean that the package is still in transit or that the carrier has omitted this part of the tracking information. There are also some shipping methods that do not support full-process logistics tracking. In this case you can directly contact the sender or the local shipping company in the destination country to formally investigate the whereabouts of your package.`,
    "help_27": `track.a2a56.com is a logistics tracking website that provides you with convenient package tracking service, but it has no impact on the delivery process or delivery time. Regarding the actual transportation of the package, it is determined by the carrier of your package. .`,
    "help_28": `7. Can you confirm my address by tracking number?`,
    "help_29": `Sorry, we cannot confirm the package address by tracking number.`,
    "help_30": `track.a2a56.com is a third-party independent site for tracking Latin American market postal parcels as well as international express parcels. We only update the shipping company's shipping status about your package in sync on the website. However, it is important to note that we are not directly involved in specific shipping matters, nor are we involved in online transactions.`,
    "help_31": `The address is private information, only the shipper (i.e. the seller) and the carrier know your package address information. If you need to confirm the address, we recommend that you contact the package sender directly.`,
    "help_32": `8. What is the difference between registered parcel and surface mail?`,
    "help_33": `At present, the main logistics method of cross-border e-commerce small parcel logistics, the consignor has a variety of options when sending international parcels, including registered parcels, express delivery and various special line channels.`,
    "help_34": `Registered post is a trackable postal shipping service, while surface mail is a shipping method that does not have tracking information.`,
    "help_35": `9. How long do I have to wait to receive my package?`,
    "help_36": `Unfortunately, the carrier does not have a promised time limit for arrival, so track.a2a56.com cannot get an estimated time when you will receive your package.`,
    "help_37": `However, based on our statistical analysis, it usually takes 15 to 35 days to complete the delivery process for surface or registered parcels.`,
    "help_38": `10. Can track.a2a56.com confirm that my tracking number is valid?`,
    "help_39": `We can confirm whether the tracking number is a valid number according to the tracking number rules, but we cannot confirm whether the package is sent to you or someone else.`,
    "help_40": `Therefore, please choose a reputable seller as much as possible. To be cautious, please understand the seller's store situation in detail before placing an order to ensure the safety of Internet transactions.`,
    "help_41": `track.a2a56.com is a third-party independent site for postal parcels in the Latin American market as well as international express parcels. However, it is important to note that we are not directly involved in specific shipping matters, nor are we involved in online transactions.`,
    "help_42": `11. The package status shows "Too long in transit", what does it mean?`,
    "help_43": `"Transportation for too long" means that your package may be delayed in delivery. If the logistics information has not been updated for a long time at the same time, if you have not received the package for more than 30 days, we recommend that you contact the shipper in time. Contact so that they can investigate whether they have shipped the shipment, starting the investigation from the carrier in the shipping country.`,
    "help_44": `12. Why is my package delivered to the wrong address?`,
    "help_45": `At present, most of the processing of parcels is automated, but a large number of manual operations are still involved in the entire processing process, so there will be occasional delivery errors. Although this happens in a very small percentage, if you experience a package delivery error, please contact your shipper, or the store where you purchased the item, to confirm that your address details are correct and contact the destination carrier process or visit the carrier's official website for further assistance.`,
    "help_46": `13. Why is my package in "Possibly Unusual" status?`,
    "help_47": `A "Possibly Unusual" status means that your package has encountered an unusual condition for some indeterminate reason. The most common reasons are: the package is returned to the sender or temporarily stored in the destination country customs warehouse or the carrier's operation center due to incomplete address/involving customs investigation issues/prohibited goods/infringing goods/loss or damage. The reason for returning the package will be written on the package.`,
    "help_48": `If your package appears abnormal, please consult your local carrier or customs for assistance.`,
    "help_49": `14. What should I do if my package needs to be cleared through customs?`,
    "help_50": `For international express delivery, customs declaration and clearance is a mandatory procedure.`,
    "help_51": `If your package is investigated by customs and seized according to law, you can contact the carrier to learn how to clear customs in the destination country, and you can also contact customs to learn what customs clearance materials need to be prepared.`,
    "help_52": `15. What does it mean when the package status shows "Failed to deliver"?`,
    "help_53": `A status of "Failed to deliver" means that your package carrier has attempted to deliver, but the delivery has not been successful.`,
    "help_54": `Most postal carriers temporarily hold your package for a short period of time. If you miss your first delivery, you will need to contact your local carrier to coordinate a second delivery.`,
    "help_55": `If there is no instruction to modify the delivery address for this package after the failed delivery, the package will be returned to the original sender.`,
    "help_56": `16. If the address is incomplete, can the package arrive smoothly?`,
    "help_57": `In most cases, the package can be delivered properly. However, delays are possible, depending on whether you have provided an accurate, complete and detailed address.`,
    "help_58": `Packages will be sorted and processed many times during transportation. If the address is incomplete and sufficient, it may be sent to the wrong transfer station, which will cause delays. If the address is not detailed and lacks primary information, it will be returned to the sender as undeliverable.`,
    "help_59": `17. My package is in "Arrived for Pickup" status, do I need to pick it up?`,
    "help_60": `"Arrived to be picked up" means that your package has arrived at the block express station in the destination country or is being delivered. The recipient can try to contact the carrier to inquire about the delivery time or pick up the package by yourself.`,
    "help_61": `18. The status of the package has been "in transit" for a long time, where is its specific location?`,
    "help_62": `International shipping usually takes longer. According to our aging statistics, it usually takes about 15-30 days for a small package, 7-20 days for ePacket, and about 14 days for EMS. If the location results you obtained from track.a2a56.com are not satisfactory to you, we recommend contacting the sender or transporter for more information.`,
    "help_63": `19. The tracking information shows that a delivery notice is left, why did I not receive it?`,
    "help_64": `The parcel delivery notice is a paper sticky note, which is usually put into the mailbox of the recipient's address or by the door by the courier. If you cannot find a notice, the easiest way is to contact your local carrier directly. They will tell you where to pick up your package.`,
    "help_65": `20. The package status shows "Successful delivery", but I did not receive it, what should I do?`,
    "help_66": `Under normal circumstances, "delivered successfully" means that the package has been delivered to the recipient. If the recipient does not receive the package, you can try the following solutions to find out the actual status of the package: confirm the delivery address with the sender / ask if someone else will collect it / contact the shipping company of this package.`,
    "help_67": `21. Why does the order I purchased from China show that the country of origin is "United States"?`,
    "help_68": `There are a wide variety of carriers in China for online store operators to choose from, especially in the e-commerce industry. For example, Spain Post, Canada Post, etc.`,
    "help_69": `When you order items online, sellers take a variety of factors into consideration and choose the shipping service they think is best for you.`,
    "help_70": `22. The package was delivered to the wrong address and signed for it, what should I do?`,
    "help_71": `If you confirm that your package has been delivered to someone else, please contact the sender directly to confirm that the address on the invoice is correct. If it is correct, you can contact the local carrier directly to check your package delivery information.`,
    "help_72": `track.a2a56.com is a third-party independent site that focuses on tracking postal parcels in the Latin American market and international express parcels. However, it is important to note that we are not directly involved in the specific transportation of your package.`,
    "delivered": `Package could not be found`,
    "in_transit": `SHIPPING STARTED`,
    "expired": `Expired`,
    "alert": `Alert`,
    "locate": `LOCATE`,
    "terms_of_use": `Terms of use`,
    "copyright01": `Copyright`,
    "about": `about`,
    "support": `Support`,
    "comment": `Comment`,
    "help01": `Help`,
    "about02": `About us?`,
    "contact_01":`a2a56 is a tracking platform that provides information from multiple international couriers/carriers.`,
    "contact_02": `a2a56 is not in charge of the delivery of your order. For lost or damaged parcels and packages and any other delivery issues, you should contact the shipper or place where you placed your order or the carrier/courier who is in charge of delivering your package.`,
    "contact_form_01": `Let us know what you think so we can provide personalized assistance within 24 hours.`,
    "contact_form_02": `Name and surname`,
    "contact_form_03": `Enter your email@example.com`,
    "contact_form_04": `Information about a package`,
    "contact_form_05": `API & webhook solutions`,
    "contact_form_06": `Other`,
    "contact_form_07": `Your message`,
    "contact_form_08": `Send`,
    "contact_form_09": `Select a theme:`,
    "contact_03": `Need help?`,
    "contact_04": `Visit our help center and look for information on all kinds of topics about which you have doubts.`,
    "no_results_header": `There are no results`,
    "no_results_text": `Enter the tracking number of your package.`,
    "hy": `Hy`,
    "how_can_we_help_you": `How can we help you?`,
    "search_in_our": `Search our knowledge base or browse the questions below.`,
    "faq": `Frequently asked questions`,
    "help_center_button": `a2a56 Help Center`,
    "table_of_contents": `Table of Contents`,
    "what_is": `What is`,
    "31_track": `a2a56?`,
    "what_is_description": `It is a leading logistics information services platform in Latin America, providing personalized information flow services for logistics and express delivery to Latin America.`

}