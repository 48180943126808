import {
  VStack,
  Center,
  Text,
  Box,
  HStack,
  ChevronRightIcon,
  Pressable,
  Image,
} from 'native-base';
import { useEffect, useState } from 'react';
import { getDateFormat, getHourFormat } from '../helper/date.helper';
import CircleSvg from '../svg/circle';
import OkSvg from '../svg/ok';
import { TrackingEvent, TrackingResult } from '../types';
import { TrackingNumberResultHash } from '../screens/ResultScreen';
import { getHostname } from '../config';
import { toCamelKeys } from 'keys-transform';
import CustomTextSkeleton from './CustomSkeketonText';
import { useTranslation } from 'react-i18next';
import { border } from 'native-base/lib/typescript/theme/styled-system';

function camelize<T = any>(data: any): T {
  return toCamelKeys(data) as T;
}

export type Props = {
  trackingNumberResultHash: TrackingNumberResultHash;
  trackingNumberResultFetchHandler: (
    trackingNumber: TrackingNumberResultHash['trackingNumber'],
    result: TrackingResult | 'loading' | undefined,
  ) => any;
  setOpenDialog: (state: boolean) => any;
  setImage: (img: string) => any;
};

//509998770010
//990071619234
const fetchTrackingData = async (
  trackingNumber: string,
): Promise<TrackingResult | undefined> => {
  try {
    const response = await fetch(
      `${getHostname(
        window.location.hostname,
      )}/api/v1/requests?q=${trackingNumber}`,
      // `https://api.a2a56.com/api/v1/requests?q=${trackingNumber}`
    );
    const data = await response.json();
    return camelize<TrackingResult>(data);
  } catch (e) {
    return undefined;
  }
};

const TrackingResultRow: React.FC<Props> = ({
  trackingNumberResultHash,
  trackingNumberResultFetchHandler,
  setImage,
  setOpenDialog
}) => {
  const { t } = useTranslation();
  const [showEventList, setShowEventList] = useState<boolean>(false);

  useEffect(() => {
    const fetchStatus = trackingNumberResultHash.fetchStatus;
    if (
      fetchStatus === 'error' ||
      fetchStatus === 'success' ||
      fetchStatus === 'loading'
    ) {
      return;
    }

    trackingNumberResultFetchHandler(
      trackingNumberResultHash.trackingNumber,
      'loading',
    );

    fetchTrackingData(trackingNumberResultHash.trackingNumber)
      .then(result => {
        trackingNumberResultFetchHandler(
          trackingNumberResultHash.trackingNumber,
          result,
        );
      })
      .catch(e => {
        console.error(e);
        trackingNumberResultFetchHandler(
          trackingNumberResultHash.trackingNumber,
          undefined,
        );
      });

    // return () => {
    //   shouldUpdate = false;
    // };
  }, [trackingNumberResultHash, trackingNumberResultFetchHandler]);

  const trackingResult = trackingNumberResultHash.trackingResult;
  const error = trackingNumberResultHash.fetchStatus === 'error';

  return (
    // <Box
    //   p={4}
    //   width="911px"
    //   borderRadius={10}
    //   mb={2}
    //   borderWidth={1}
    //   borderColor="#d1d5db">
    <div style={{ boxShadow: '0px 8px 20px #0000000D', width: (window.innerWidth * 0.7) - 322 - 30 + 'px', borderRadius: "20px", padding: "12px", border: "1px solid #d1d5db", maxWidth: "911px", marginBottom: "10px" }}>

      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center">
          <Pressable
            //@ts-ignore
            onPress={() => {
              if (error || trackingResult!.status === 'not-found') {
                return;
              }
              setShowEventList(!showEventList);
            }}>
            <HStack>
              {trackingNumberResultHash.trackingResult?.delivered && (
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/delivered_background.svg`, }} alt="no_trace_background_icon" width={9} height={8} />
              )}
              {trackingNumberResultHash.trackingResult?.status === 'in_transit' && (
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/transit_background.svg`, }} alt="no_trace_background_icon" width={9} height={8} />
              )}
              {trackingNumberResultHash.trackingResult?.status === 'expired' && (
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/expired_background.svg`, }} alt="no_trace_background_icon" width={9} height={8} />
              )}
              {trackingNumberResultHash.trackingResult?.status === 'alert' && (
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/alert_background.svg`, }} alt="no_trace_background_icon" width={9} height={8} />
              )}
              {!trackingNumberResultHash.trackingResult?.delivered
                && trackingNumberResultHash.trackingResult?.status !== 'in_transit'
                && trackingNumberResultHash.trackingResult?.status !== 'expired'
                && trackingNumberResultHash.trackingResult?.status !== 'alert' && (
                  <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/no_trace_background.svg`, }} alt="no_trace_background_icon" width={9} height={8} />
                )}
              <Text ml={6} bold fontSize="20px" color="#293D8B">
                {trackingNumberResultHash.trackingNumber}
              </Text>
            </HStack>
          </Pressable>
        </HStack>
      </HStack>
      <TrackingResultRowContent
        trackingNumberResultHash={trackingNumberResultHash}
        showEventList={showEventList}
        setOpenDialog={setOpenDialog}
        setImage={setImage}
      />
    </div>

    // </Box>
  );
};

const TrackingResultRowContent: React.FC<{
  trackingNumberResultHash: TrackingNumberResultHash;
  showEventList: boolean;
  setOpenDialog: (state: boolean) => any;
  setImage: (img: string) => any;
}> = ({ trackingNumberResultHash, showEventList, setOpenDialog, setImage }) => {
  const trackingResult = trackingNumberResultHash.trackingResult;
  const error = trackingNumberResultHash.fetchStatus === 'error';
  const success = trackingNumberResultHash.fetchStatus === 'success';
  const isLoaded = success || error;
  const events = trackingResult?.data?.events || [];
  const { t } = useTranslation();

  const getCountry = (country: string | null): string | null => {
    if (!country) {
      return null
    }
    switch (country.toLowerCase()) {
      case 'china':
        return 'CN'
      case 'mexico':
        return 'MX'
      case 'chile':
        return 'CL'
      case 'republica dominicana':
        return 'DO'
      case 'argentina':
        return 'AR'
      default:
        return country
    }
  }

  return (
    <>
      <HStack pl={9} pr={9} justifyContent="space-between" alignItems="center">
        <Box width={120}>
          {trackingResult?.delivered ? (
            <HStack
              width="106px"
              height="25px"
              backgroundColor="#c5f9d5"
              borderRadius={14}
              alignItems="center"
              justifyContent="center">
              <OkSvg />
              <Text ml={1} fontFamily="Segoe UI" color="#009c30">
                {t('delivered')}
              </Text>
            </HStack>
          ) : (
            <CustomTextSkeleton
              bold
              loading={!isLoaded}
              placeholderText={'Loading package...'}
              text={(() => {
                if (error || trackingResult?.status === 'not-found') {
                  return t('not_found');
                }

                if (success) {
                  return t(trackingResult?.status || '') || '';
                }

                return '';
              })()}
            />
          )}
        </Box>
        {events.length > 1 && (
          <HStack
            width="255px"
            alignItems="stretch"
            justifyContent="space-evenly">
            <VStack>
              <CustomTextSkeleton
                color="#000000"
                loading={!isLoaded}
                placeholderText={'2022-30-30'}
                fontFamily="Segoe UI"
                text={
                  (events.length > 0 &&
                    getDateFormat(events[events.length - 1].datetime)) ||
                  ''
                }
              />
              <CustomTextSkeleton
                color="#aaaaaa"
                loading={!isLoaded}
                placeholderText={'2022-30-30'}
                fontFamily="Segoe UI"
                text={
                  (events.length > 0 &&
                    getHourFormat(events[events.length - 1].datetime)) ||
                  ''
                }
              />
            </VStack>
            <Box width="2px" backgroundColor="#d3d2d2" />
            <VStack>
              <CustomTextSkeleton
                color="#000000"
                loading={!isLoaded}
                placeholderText={'2022-30-30'}
                fontFamily="Segoe UI"
                text={
                  (events.length > 0 &&
                    getDateFormat(trackingResult!.data.events[0].datetime)) ||
                  ''
                }
              />
              <CustomTextSkeleton
                color="#aaaaaa"
                loading={!isLoaded}
                placeholderText={'2022-30-30'}
                fontFamily="Segoe UI"
                text={
                  (events.length > 0 &&
                    getHourFormat(trackingResult!.data.events[0].datetime)) ||
                  ''
                }
              />
            </VStack>
          </HStack>
        )}

        {events.length === 1 && (
          <HStack
          width="255px"
          alignItems="stretch"
          justifyContent="center">
          <VStack>
            <CustomTextSkeleton
              color="#000000"
              loading={!isLoaded}
              placeholderText={'2022-30-30'}
              fontFamily="Segoe UI"
              text={
                (events.length > 0 &&
                  getDateFormat(events[events.length - 1].datetime)) ||
                ''
              }
            />
            <CustomTextSkeleton
              color="#aaaaaa"
              loading={!isLoaded}
              placeholderText={'2022-30-30'}
              fontFamily="Segoe UI"
              text={
                (events.length > 0 &&
                  getHourFormat(events[events.length - 1].datetime)) ||
                ''
              }
            />
          </VStack>
        </HStack>
        )}

        <Center>
          <HStack width="153px">
            <CustomTextSkeleton
              loading={!isLoaded}
              placeholderText={'Origin'}
              fontFamily="Segoe UI"
              color="#000000"
              flag
              text={success ? getCountry(trackingResult!.from) || '' : ''}
            />
            <VStack alignItems="center">
              <Box top={0}>
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/flag_arrow.svg`, }} alt="right_arrow" width={5} height={5} />
              </Box>
              <CustomTextSkeleton
                loading={!isLoaded}
                placeholderText={'Carrier'}
                fontFamily="Segoe UI"
                color="#000000"
                text={trackingResult?.carrier?.toUpperCase() || 'Unknown'}
              />
            </VStack>
            <CustomTextSkeleton
              loading={!isLoaded}
              placeholderText={'Destination'}
              fontFamily="Segoe UI"
              color="#000000"
              flag
              text={success ? getCountry(trackingResult!.to) || '' : ''}
            />
          </HStack>
        </Center>

        {trackingResult && trackingResult.pod && (
          <button onClick={() => {setImage(trackingResult.pod || ''); setOpenDialog(true)}} style={{backgroundColor: "#c5f9d5", border: "none", padding: '5px', borderRadius: '3px'}}>
                <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/eye-svgrepo-com.svg`, }} alt="right_arrow" width={5} height={5} />
          </button>
        )}
      </HStack>
      {showEventList && <EventList events={events} />}
    </>
  );
};

const EventList: React.FC<{ events: TrackingEvent[] }> = ({ events }) => {
  return (
    <VStack pt={4}>
      {events.map((e, i) => {
        return (
          <HStack key={i} width="70%">
            <VStack ml={5}>
              <CircleSvg></CircleSvg>
              <Box mt={0.5} mb={0.5} ml={2} width={0.1} flex={1} height="100%" borderLeftWidth={0.1} borderLeftColor={i < events.length - 1 ? "#d1d5db" : '#ffffff'} borderLeftStyle="dashed" />
            </VStack>
            <HStack ml={3} width="550px" justifyContent="space-between" mt={-1}>
              <Text fontSize="15px" fontFamily="Segoe UI" color="#4c66fb">{e.datetime}</Text>
              <VStack mb={3} width="50%">
                <Text width="100%" textAlign="left" fontSize="15px" fontFamily="Segoe UI" bold color="#000000">{e.description}</Text>
                <Text fontSize="15px" fontFamily="Segoe UI" textTransform="uppercase" color="#000000">{e.location ? e.location : ' '}</Text>
              </VStack>
            </HStack>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default TrackingResultRow;
