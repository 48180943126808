interface Props {
    img: string | undefined
    close: () => any
}

export const ImageDialog = ({img, close}: Props) => {
    return (
        <div onClick={close} style={{position: 'absolute', width: '100%', height: '100vh',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{backgroundColor: '#dedede', padding: '10px', borderRadius: '5px', zIndex: 1000}}>
            <img src={img} alt="" width="350px" />
        </div>
        <div style={{width: '100%', height: '100vh', backgroundColor: '#33333373', position: 'absolute'}}></div>
        </div>
    )
}