export const translation = {
    "landing_text": "Obtenga información de seguimiento de servicios postales y de mensajería",
    "landing_header": "La solución de seguimiento de paquetes líder en América Latina",
    "query": "Consulta",
    "about Us": 'Acerca de Nosotros',
    "contact us": 'Contáctenos',
    "get_in_touch": "Ponte en contacto con nosotros",
    "get_in_touch_description": "La plataforma líder de servicios integrados de información logística en América Latina, personaliza los servicios de flujo de información para logística y entrega urgente en América Latina. Para servicios o sugerencias más personalizados, contáctenos",
    "contact": "CONTACTAR",
    "help_center": 'Centro de ayuda',
    "security policy": 'Política de Seguridad',
    "Provide smooth query": 'Proporcionar consulta fluida',
    "content_1": 'Identifique automáticamente a todos los transportistas cooperativos, realice un seguimiento de la información de logística durante todo el proceso, sin seleccionar un proveedor de logística, se puede obtener información de trayectoria completa y precisa en un solo paso, y todos los nodos se presentan claramente.',
    "Focus on the Latin American market": 'Centrado en el mercado latinoamericano',
    "content_2": 'Ha cooperado con los principales proveedores de logística en América Latina, y los transportistas cooperativos cubren los principales países de América Latina. Si necesita realizar un seguimiento de más paquetes de los transportistas, puede enviar sus comentarios al servicio de atención al cliente y haremos todo lo posible para apoyar a los transportistas que necesita.',
    "Customer service across time differences": 'Atención al cliente a través de las diferencias horarias',
    "content_3": 'Cuenta con un servicio de atención al cliente multilingüe profesional y un equipo de investigación y desarrollo técnico chino y extranjero, dedicado a resolver rápidamente los problemas de los usuarios, brindando a los usuarios 6*24 horas de servicio en línea.',
    "User privacy data protection": 'Protección de datos de privacidad del usuario',
    "content_4": 'Esperamos mantener una relación de cooperación sana y a largo plazo con las plataformas de comercio electrónico, los vendedores y los proveedores de logística. Cumplir con el Reglamento General de Protección de Datos para proteger los datos y la privacidad de los usuarios.',
    "advantage": 'Ventaja',
    "world_text_1": 'La plataforma de gestión y servicios digitales de logística líder en América Latina,',
    "world_text_2": 'Comprometidos con la construcción de soluciones de información logística más independientes e inteligentes.',
    "agreement": 'Convenio',
    "copyright": 'Aviso de copyright',
    "Privacy Policy": 'Política de privacidad',
    "Feedback": 'Realimentación',
    "contact_us_banner_text": 'La plataforma de servicios integrados de información logística líder en América Latina, personaliza los servicios de flujo de información para la logística de América Latina y la entrega urgente para usted. Para servicios o sugerencias más personalizados, contáctenos',
    "Enter tracking number": 'Ingrese los números de seguimiento',
    "about_1": 'track.a2a56.com proporciona servicios prácticos de seguimiento de carga y servicios de datos de flujo logístico para la mayoría de las empresas de comercio electrónico.',
    "about_2": 'Fundada en México, se enfoca en comercio electrónico y logística transfronteriza para el mercado latinoamericano.',
    "about_3": 'En track.a2a56.com, creemos que la información de seguimiento es la clave para atraer clientes, y la mejor manera de mejorar la experiencia es proporcionar información de seguimiento en un solo lugar sin tener que elegir activamente el sitio de Carriers nuevamente. Creemos que el acceso a la información de nuestro sitio web se aplica a todos los participantes del comercio electrónico, ya sea usted un comprador, un proveedor de logística o un vendedor.',
    "about_4": 'Esperamos que disfrute utilizando el servicio de seguimiento de paquetes de track.a2a56.com y nos complace brindarle la mejor experiencia posible. Si tiene alguna pregunta o comentario, ¡no dude en contactarnos!',
    "security_1": 'Gracias por visitar el sitio web track.a2a56.com, las aplicaciones móviles, los dispositivos y otro software relacionado. Esta política se aplica a la provisión de productos y servicios de track.a2a56.com (incluidos terminales móviles, sitios web, mini programas, etc.), en lo sucesivo denominados "nosotros" o "track.a2a56.com". Respetamos y tomamos muy en serio su privacidad en línea y esperamos que comprenda nuestra política de privacidad para proteger su información personal. Esta Política de privacidad también se aplica a todos nuestros servicios y productos, ya sea a través del sitio principal, la aplicación móvil o el sitio web móvil de a2a56.com.',
    "security_2": 'La política de privacidad se explica a continuación:',
    "security_3": 'Si continúa visitando nuestro sitio web, acepta los términos contenidos en esta Política de Privacidad:',
    "security_4": 'definición',
    "security_5": 'Datos personales: se refiere a cualquier información sobre una persona física que pueda identificarse directa o indirectamente, en particular por referencia a identificadores como nombres, números de identificación, datos de ubicación o identificadores en línea.',
    "security_6": 'Tratamiento: significa cualquier operación realizada sobre datos personales, tales como recolección, registro, organización, estructuración, almacenamiento, adaptación o cualquier tipo de divulgación u otro uso.',
    "security_7": 'Regístrese en nuestro sistema de servicio',
    "security_8": 'Nuestro sitio web ofrece la opción de obtener el boletín. Para utilizar este servicio, debe configurar una cuenta. Con respecto al registro de una cuenta y su uso posterior, procesamos:',
    "security_9": 'Información proporcionada por el registro (como su nombre y dirección de correo electrónico) Información relacionada con las funciones de inicio de sesión de la cuenta (como detalles de inicio de sesión y contraseña) Información que envía (como por correo electrónico o formularios de información del sitio web)',
    "security_10": 'Trataremos los datos personales que nos facilite:',
    "security_11": 'Verificar su identidad cuando inicia sesión\nProporcionarle los servicios y la información que solicita a través del sitio web\nAdministrar su cuenta\nComunicarnos con usted\nPublicidad y análisis',
    "security_12": 'Sus datos personales se conservarán mientras su cuenta de usuario se utilice sin excepción en los siguientes servicios específicos. Después de eliminar su cuenta, sus datos personales se bloquearán y eliminarán en la próxima limpieza de la base de datos. Las obligaciones legales de almacenamiento o posibles acciones legales debido a mala conducta en los Servicios o problemas de pago pueden dar lugar a que sus datos personales se conserven durante períodos de tiempo más prolongados. En este caso, le notificaremos en consecuencia.',
    "security_13": 'Comunicación',
    "security_14": 'A través de su dirección de correo electrónico, puede suscribirse a nuestro boletín y, si acepta recibir dichos boletines, puede suscribirse a nuestro boletín, que le brinda las últimas actualizaciones sobre su paquete y también puede contener anuncios de temas específicos.',
    "security_15": 'Si no tiene una cuenta antes, también puede completar su registro durante el pedido del boletín. Puede darse de baja de este servicio a través del enlace proporcionado en cada comunicado de prensa.',
    "security_16": 'Publicidad Online',
    "security_17": 'Este sitio web utiliza un servicio de publicidad en línea para mostrarle anuncios que coincidan con sus intereses. El anuncio correspondiente está marcado con el nombre del proveedor, por ejemplo, "Google-Ad". Además, este servicio nos ayuda a mejorar nuestro sitio web y hacer que su visita sea más interesante. Para ello, recopilamos información estadística sobre usted, que es procesada por el proveedor.',
    "security_18": 'Google AdWord',
    "security_19": 'Usamos Google Adwords para anunciar en nuestro sitio web y sitios web de terceros a visitantes anteriores de nuestro sitio web. Los anuncios de Google son proporcionados por Google LLC, 1600 Theatre Parkway Mountain View, CA 94043, EE. UU. ("Google").',
    "security_20": 'Los anuncios se muestran en función de la información almacenada en las cookies que Google coloca en su computadora. Los archivos de texto incluyen información sobre su visita a nuestro sitio web, en particular, las vistas de productos leídas para recomendaciones de productos específicos durante visitas posteriores a este o a sitios web de terceros. La cookie contiene un alias aleatorio. Si visita nuestro sitio web y ve nuestros productos en un momento determinado, Google puede reconocerlo por alias. Ni nosotros ni Google combinaremos esta información con sus datos personales y no divulgaremos sus datos personales a terceros. Puede evitar el almacenamiento y uso de información en las cookies colocadas por Google haciendo clic en el siguiente enlace https://www.google.com/ads/preferences y configurando sus preferencias.',
    "security_21":'Para obtener más información sobre la Política de privacidad de Google, consulte https://privacy.google.com/ y https://www.google.com/policies/privacy/.',
    "security_22":'Audiencias personalizadas de Google Ads y Facebook',
    "security_23":'En este sitio web, utilizamos la función de remarketing o "audiencias similares" de AdWords, proporcionada por Google Inc., 1600 Theatre Parkway Mountain View, CA 94043, EE. UU., en lo sucesivo denominado "Google".',
    "security_24":'Google utiliza cookies/tecnologías de seguimiento, que son archivos de texto almacenados en su computadora, que nos permiten analizar su uso de nuestro sitio web. La información generada por la tecnología de cookies/seguimiento sobre su uso de este sitio web (incluida su dirección IP) se transmitirá y almacenará en un servidor de Google en los Estados Unidos. Después de eso, Google eliminará los últimos tres dígitos de esta dirección IP, de modo que esta dirección IP ya no se pueda relacionar sin ambigüedades con datos personales. Google cuenta con la certificación del Escudo de privacidad y acepta cumplir con el Tratado del Escudo de privacidad UE-EE. UU. publicado por el Departamento de Comercio de EE. UU. con respecto a la recopilación, el uso y el almacenamiento de datos personales de los estados miembros de la UE. Google utilizará esta información para analizar su uso del sitio web, crear informes sobre la actividad del sitio web para los operadores del sitio web y proporcionar otros servicios relacionados con el uso del sitio web e Internet. Además, si así lo exige la ley',
    "security_25":'Google puede transferir esta información a terceros si así lo requiere la ley o si el tercero procesa datos en nombre de Google.',
    "security_26":'Los proveedores de terceros, incluido Google, publican anuncios en sitios web en Internet. Los proveedores externos (incluido Google) utilizan cookies almacenadas/información de seguimiento para publicar anuncios basados ​​en visitas anteriores de los usuarios a este sitio web.',
    "security_27":'Para obtener información adicional sobre el análisis anónimo del comportamiento de búsqueda, consulte:',
    "security_28":'https://support.google.com/analytics/answer/6004245?hl=es',
    "security_29":'https://www.google.com/policies/technologies/ads/',
    "security_30":'Puede objetar en cualquier momento la recopilación y el almacenamiento de datos con fines de remarketing (esta objeción será válida en el futuro, pero no retroactivamente) desactivando la publicidad basada en intereses en Google o desactivando los servicios en el sitio web Network Advertising Initiative. Nota: En este caso, es posible que ya no pueda utilizar todas las funciones de este sitio web. Al utilizar este sitio web, acepta el procesamiento de los datos recopilados por Google sobre usted en la forma y para los fines descritos anteriormente.',
    "security_31":'Además, este sitio web utiliza etiquetas de reorientación y audiencias personalizadas de Facebook Inc., 1601 Southern California Avenue, Palo Alto, CA 94304, EE. UU., en lo sucesivo denominado "Facebook".',
    "security_32":'Cuando visite nuestras páginas web, la etiqueta de remarketing establecerá una conexión directa entre su navegador y los servidores de Facebook. De esta forma, Facebook sabrá que ha visitado nuestras páginas utilizando su dirección IP. Esto permitirá que Facebook haga coincidir sus visitas a nuestras páginas con su cuenta de usuario. La información obtenida de esta manera se puede utilizar para mostrar anuncios de Facebook. Tenga en cuenta que, como proveedor de la página web, no recibimos ninguna información sobre el contenido de los datos transmitidos por Facebook y su uso.',
    "security_33":'Con respecto al uso de Audiencias personalizadas, nos gustaría señalar que Facebook y terceros utilizan cookies, balizas web o tecnologías similares para recopilar o recopilar información en este sitio web. Con base en los datos que obtenemos, podemos mejorar la eficiencia de nuestras campañas de Facebook, por ejemplo, organizando mostrar contenido o anuncios solo a los visitantes del sitio web. Los datos recopilados de esta manera se cifrarán y transmitirán a Facebook y serán anónimos para nosotros, por ejemplo, no podemos ver los datos personales de los usuarios individuales.',
    "security_34":'Contenido de terceros y complementos de redes sociales',
    "security_35":'Utilizamos los siguientes complementos de redes sociales: Facebook. Esto le permite comunicarse con dichos servicios y dar me gusta o comentar en nuestro sitio web. Los complementos de redes sociales admiten la comunicación directa entre su dispositivo y el servidor del proveedor de redes sociales, lo que permite que el proveedor de redes sociales se comunique con usted y recopile información sobre su navegación en nuestro sitio web. Este procesamiento se basa en el Artículo 6 1b del Reglamento de Protección de Datos. representan nuestros intereses legítimos para mejorar su experiencia en el sitio web y optimizar nuestros servicios.',
    "security_36":'La transferencia de datos personales se produce tenga o no una cuenta con el proveedor.',
    "security_37":'Tenga en cuenta que no controlamos el alcance de los datos personales recopilados por el proveedor del complemento respectivo ni conocemos el propósito del procesamiento o el período durante el cual se conservarán sus datos personales.',
    "security_38":'Para obtener más información sobre el procesamiento de sus datos personales por parte de los proveedores en el curso de sus operaciones, consulte sus respectivas políticas de privacidad. Además, recibirá más información sobre sus derechos y configuración de privacidad.',
    "security_39":'a) Complemento social de Facebook',
    "security_40":'Utilizamos los llamados complementos sociales ("complementos") en el sitio de redes sociales facebook.com proporcionado por Facebook, Inc., 1601 Southern California Avenue, Palo Alto, CA 94304, EE. UU. ("Facebook"). Los complementos se pueden identificar por uno de los logotipos de Facebook (una "f" blanca en un mosaico azul o una marca de "Me gusta") o el texto adicional "Complementos sociales de Facebook". Se puede acceder a una lista de complementos sociales de Facebook y su apariencia en: https://developers.facebook.com/docs/plugins/.',
    "security_41": `Si un usuario visita uno de estos sitios web utilizando dicho complemento, el navegador del usuario se conecta directamente a los servidores de Facebook. El complemento y su contenido se sirven directamente en los servidores de Facebook y en el sitio web del navegador del usuario.`,
    "security_42": `Los motivos y el alcance de la recopilación de datos, así como la información sobre los datos procesados ​​y utilizados por Facebook, así como los derechos de los usuarios a este respecto y las opciones de configuración para proteger la privacidad de los usuarios, consulte las siguientes opciones: https ://www.facebook.com/policy.php.`,
    "security_43": `Si un usuario es miembro de Facebook y no desea que Facebook recopile datos personales a través de esta página y vincule estos datos con datos almacenados en Facebook, el usuario debe cerrar sesión en Facebook antes de ingresar a esta página.`,
    "security_44": `Los usuarios también pueden bloquear los complementos de Facebook utilizando un complemento para el navegador del usuario (por ejemplo, "Bloqueador de Facebook").`,
    "security_45": `Privacidad y seguridad en línea para menores`,
    "security_46": `Respetamos mucho la privacidad de los menores. No recopilamos a sabiendas y deliberadamente información personal de menores de 18 años. Si continúa visitando o utilizando los servicios de este sitio, significa que garantiza que ha cumplido los 18 años o utiliza los servicios de este sitio bajo la supervisión de un padre o tutor. \nSi es menor de 18 años, no nos envíe ninguna información personal y confíe en un padre o tutor para que lo ayude a usar este sitio web.`,
    "security_47": `Términos complementarios para visitantes de países de la UE`,
    "security_48": `Esta parte de esta Política de privacidad solo se aplica a los visitantes de los estados miembros de la UE que utilizan nuestros servicios y productos, y solo sirve como complemento de esta Política de privacidad. \nSujeto a las leyes aplicables, tiene derecho a oponerse o solicitar la restricción del procesamiento de su información personal, y solicitar acceso, portabilidad, corrección y eliminación de su información personal. El uso de su información se basa en su consentimiento, que también puede retirar en cualquier momento sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. \nPor favor, póngase en contacto con nosotros a través de nuestro correo electrónico a2a56.com para enviar una solicitud.`,
    "security_49": `La seguridad`,
    "security_50": `Contamos con medidas de seguridad razonables para evitar la pérdida, el mal uso y la alteración de los datos personales bajo nuestro control. Por ejemplo, nuestras políticas de seguridad y privacidad se revisan periódicamente y se mejoran según sea necesario, y solo el personal autorizado tiene acceso a los datos personales. Si bien no podemos asegurar ni garantizar que la información nunca se perderá, utilizará indebidamente o alterará, haremos todos los esfuerzos razonables para evitar la pérdida, el uso indebido o la alteración de la información.`,
    "security_51": `Debe recordar que enviar información a través de Internet nunca es completamente seguro. No podemos garantizar la seguridad de la información que transmite a través de Internet, y cualquier envío de este tipo es bajo su propio riesgo.`,
    "security_52": `Retención de datos`,
    "security_53": `Nos esforzamos por mantener nuestro procesamiento de sus datos personales lo más limitado posible. Si no se especifica un período de retención específico en esta política, sus datos personales solo se conservarán durante el tiempo que necesitemos para cumplir con el propósito para el que los recopilamos y, si corresponde, durante el tiempo que exijan los requisitos legales de retención.`,
    "security_54": `Tus derechos`,
    "security_55": `Según las leyes que se aplican a usted, puede tener derecho a ejercer algunos o todos los siguientes derechos:`,
    "security_56": `Para solicitar (i) información sobre si sus datos personales se retienen y (ii) acceso y/o copia de sus datos personales retenidos, incluido el propósito del procesamiento, las categorías de datos personales relevantes, los destinatarios de los datos y la expectativa potencial de retención;`,
    "security_57": `Solicitar la corrección, eliminación o restricción de sus datos personales, tales como: (i) los datos personales están incompletos o son inexactos, (ii) ya no son necesarios para el propósito para el cual se recopilaron los datos personales, o (iii) la base por aprobación es revocada;`,
    "security_58": `Negarse a proporcionar sin perjuicio de las actividades de procesamiento de datos realizadas antes del retiro, y retirar su decisión de aprobación con respecto al procesamiento de sus datos personales en cualquier momento;`,
    "security_59": `Objetar el procesamiento de sus datos personales en función de sus circunstancias específicas. En este caso, por favor proporciónenos información sobre su situación específica. Después de evaluar los datos proporcionados por usted, dejaremos de procesar sus datos personales o le proporcionaremos motivos legítimos convincentes para continuar con el procesamiento;`,
    "security_60": `Para cualquier posible violación del procesamiento de sus datos personales, contáctenos:`,
    "security_61": `Requisitos: i) recibir los datos personales sobre usted que nos ha proporcionado en un formato estructurado, de uso común y legible por máquina, y (ii) transmitir dichos datos sin obstáculos de nuestra parte a otros controladores; en donde sea técnicamente factible, usted tiene el derecho a transferir datos personales directamente de nosotros a otro controlador;`,
    "security_62": `No está sujeto a ninguna decisión automatizada, incluida la elaboración de perfiles (procesamiento de decisiones automatizadas basadas en el procesamiento automatizado de datos con fines de evaluación), que tenga efectos legales sobre usted o que tengan efectos similares sobre usted.`,
    "security_63": `Puede ejercer los derechos anteriores o hacernos cualquier pregunta comunicándose con nosotros a través de info@a2a56.com.`,
    "security_64": `Nos reservamos el derecho de revisar esta política actualizando nuestro sitio web de vez en cuando. Visite el sitio web regularmente y revise nuestras respectivas políticas de privacidad actuales. Esta política se actualizó por última vez en enero de 2021.`,
    "security_65": `Tu elección`,
    "security_66": `Siempre puede optar por no proporcionar su información personal, aunque es posible que ciertas funciones de nuestro sitio web no estén disponibles.`,
    "security_67": `Puede solicitarlo enviando un correo electrónico a info@a2a56.com:`,
    "security_68": `• Corrija o actualice su información personal\n• Deje de recibir correos electrónicos en su dirección de correo electrónico\n• Deje de recibir mensajes de texto en su teléfono móvil\n• Desactive su cuenta\n• Bloquee las cookies en su computadora, lo que hace que no pueda para usar ciertos servicios en el sitio web`,
    "security_69": `Política de cookies`,
    "security_70": `#Acerca de las cookies`,
    "security_71": `Una cookie es un archivo enviado por un servidor web a un navegador web, que generalmente incluye un identificador único, que se puede enviar de vuelta al servidor cada vez que el navegador solicita una página del servidor.`,
    "security_72": `Los servidores web pueden usar cookies para identificar y rastrear a los usuarios mientras navegan por diferentes páginas del sitio web y para identificar a los usuarios que regresan al sitio web.`,
    "security_73": `Las cookies pueden ser cookies "persistentes" o cookies de "sesión". Una cookie persistente consiste en un archivo de texto enviado por un servidor web a un navegador web, que será almacenado por el navegador y seguirá siendo válido hasta la fecha de vencimiento establecida (a menos que el usuario lo elimine antes de la fecha de vencimiento). Por otro lado, las cookies de sesión caducan cuando finaliza la sesión del usuario (cuando se cierra el navegador web).`,
    "security_74": `track.a2a56.com utiliza cookies y tecnologías similares para analizar tendencias, administrar el sitio, rastrear la actividad del usuario en el sitio y recopilar información demográfica sobre toda nuestra base de usuarios. Usamos cookies para una variedad de propósitos, incluidos, entre otros, la identificación de usuarios, el seguimiento de sesiones, los datos de preferencias almacenados en el terminal local del usuario y las páginas web visitadas.`,
    "security_75": `#Cookies de terceros`,
    "security_76": `También puede recibir cookies de terceros cuando utiliza este sitio web.`,
    "security_77": `Nuestros anunciantes y proveedores de servicios pueden enviarle cookies. Podrán utilizar la información obtenida a partir de su uso de cookies:`,
    "security_78": `Realice un seguimiento de su navegador en múltiples sitios web\nobtenga anuncios que puedan ser de su interés particular.`,
    "security_79": `#Bloquear cookies`,
    "security_80": `La mayoría de los navegadores le permiten negarse a aceptar cookies. P.ej:`,
    "security_81": `Puede reducir el nivel de uso de cookies en su navegador. Si elige deshabilitar las cookies, su uso de ciertas características de este sitio web puede ser limitado.`,
    "An error occurred while getting the data from the server": `Ha ocurrido un error al obtener los datos del servidor.`,
    "help_1": `1. ¿Dónde está mi paquete? ¡Lo compré hace más de un mes!`,
    "help_2": `Vea los márgenes de tiempo de procesamiento y despacho del Correo Certificado Global/ePacket (No incluye áreas rurales):`,
    "help_3": `     • Día 1-2 Aceptado/En Origen`,
    "help_4": `     • Día 2-3 Despacho desde la oficina de clasificación para exportación/Exportando`,
    "help_5": `     • Día 2-4 Pendiente de liberación de Aduanas/Exportando`,
    "help_6": `¡Después de Exportando/Despacho, el estado del envío no será actualizado hasta que la carga llegue al siguiente destino!`,
    "help_7": `     • Día 4-10 Arribo a la oficina clasificadora de importación/En Destino`,
    "help_8": `     • Día 10-15 Pendiente de liberación de Aduanas/Importando`,
    "help_9": `     • Día 15-30 Transporte nacional y reparto en el domicilio.`,
    "help_10": `Día 60 Si no es entregado exitosamente, el paquete puede ser devuelto al remitente.`,
    "help_11": `Cuando el envío llegue a su país, si está ansioso de tener sus cosas, trate de contactar al transportista local para acelerar el reparto.`,
    "help_12": `2. ¿Cuál es la ubicación exacta de mi paquete?`,
    "help_13": `Para la mayoría de la carga internacional, los envíos, e incluso el servicio expreso, los transportistas no tienen una fecha garantizada o estimada de entrega. Los tiempos de envío pueden ser más lentos de lo que espera, principalmente por los trámites aduaneros y la programación de las aerolíneas. El transporte internacional es bastante diferente del transporte nacional.`,
    "help_14": `track.a2a56.com es un sitio independiente de terceros que se enfoca en rastrear paquetes postales en el mercado latinoamericano y paquetes exprés internacionales. Principalmente le proporcionamos datos de seguimiento obtenidos legalmente del sitio web oficial del transportista asociado correspondiente. No participamos en el envío directo de paquetes ni en el proceso de transacción en línea de sus productos.`,
    "help_15": `Si el transportista actualiza la información de logística, track.a2a56.com también se actualizará, puede intentar verificar su paquete después de un tiempo.`,
    "help_16": `3. Mi paquete fue “No encontrado”, ¿Qué hago?`,
    "help_17": `“No encontrado” significa que no disponemos de información alguna para ese número. Le recomendamos que verifique el mismo, o contacte al remitente (el vendedor donde adquirió su orden) para verificación.`,
    "help_18": `Si la información de rastreo es correcta, por favor espere 1 o 2 días después que el paquete haya sido enviado para permitir que la información de rastreo esté disponible.`,
    "help_19": `4. No he recibido mi paquete. ¿Puedo obtener un reembolso`,
    "help_20": `Le sugerimos que contacte a la empresa/remitente donde compró su orden. Ya que somos una plataforma de rastreo online, no somos responsables por la entrega de su compra.`,
    "help_21": `Además, no estamos asociados con ningún comerciante en línea. Por ello, cualquier problema relacionado con la compra o con un reembolso debe ser solicitado directamente al vendedor.`,
    "help_22": `Por lo tanto, los problemas relacionados con las transacciones en línea o los reembolsos solo pueden ser manejados por la tienda donde compró el artículo.`,
    "help_23": `5. ¿Cómo puedo solicitar un cambio de dirección?`,
    "help_24": `Regularmente, si el paquete ya ha sido enviado, esa dirección no puede ser alterada. Puede esperar hasta que la carga llegue a su país y entonces solicitar el cambio de dirección al transportista local. Para ello, vaya a la página web del transportista.`,
    "help_25": `6. Mi paquete quedó estancado en alguna parte, ¿podría darme información actualizada?`,
    "help_26": `Si su paquete no se ha actualizado con la información de logística durante mucho tiempo, puede significar que el paquete aún está en tránsito o que el transportista ha omitido esta parte de la información de seguimiento. También hay algunos métodos de envío que no admiten el seguimiento logístico de todo el proceso. En este caso, puede comunicarse directamente con el remitente o la empresa de envío local en el país de destino para investigar formalmente el paradero de su paquete.`,
    "help_27": `track.a2a56.com es un sitio web de seguimiento de logística que le brinda un conveniente servicio de seguimiento de paquetes, pero no tiene impacto en el proceso de entrega ni en el tiempo de entrega. Con respecto al transporte real del paquete, lo determina el transportista de su paquete. .`,
    "help_28": `7. ¿Podría verificar mi dirección a través del número de seguimiento?`,
    "help_29": `Lo sentimos, no podemos confirmar la dirección del paquete mediante el número de seguimiento.`,
    "help_30": `track.a2a56.com es un sitio independiente de terceros para el seguimiento de paquetes postales del mercado latinoamericano, así como paquetes exprés internacionales. Solo actualizamos el estado de envío de la empresa de envío sobre su paquete de forma sincronizada en el sitio web. Sin embargo, es importante tener en cuenta que no estamos directamente involucrados en asuntos de envío específicos, ni estamos involucrados en transacciones en línea.`,
    "help_31": `La dirección es información privada, solo el remitente (es decir, el vendedor) y el transportista conocen la información de la dirección de su paquete. Si necesita confirmar la dirección, le recomendamos que se comunique directamente con el remitente del paquete.`,
    "help_32": `8. ¿Cuál es la diferencia entre correo certificado y correo terreste?`,
    "help_33": `Los remitentes pueden elegir enviar un paquete por correo expreso o correo certificado económico. Para ítems de bajo valor, usualmente se usa un servicio sin registrar.`,
    "help_34": `Por ello, el correo certificado es rastreable y el sin registrar no lo es, o tiene información de rastreo muy limitada. De hecho, el correo terrestre será empacado en grandes contenedores y será enviado al centro de clasificación para despacho, siendo la única información que obtendrá. No existirá nueva información tras el despacho.`,
    "help_35": `9. ¿Cuándo recibiré mi paquete?`,
    "help_36": `Desafortunadamente, no es posible obtener del transportista una fecha estimada de entrega para envíos internacionales. `,
    "help_37": `Normalmente tomará de 15 a 35 días desde el despacho.`,
    "help_38": `10. ¿Podría confirmar si mi número de seguimiento es válido o no?`,
    "help_39": `Sí, podemos confirmar si se trata de un número válido de acuerdo al formato del mismo para la mayoría de los envíos. Sin embargo, no podemos confirmar si ese envío es el suyo, o pertenece a otra persona.`,
    "help_40": `Por favor, haga cuidadosa selección de sus vendedores. Antes de comprar, asegúrese de haber investigado un poco al vendedor.`,
    "help_41": `Tenga presente que track.a2a56.com NO está relacionado con ninguna transacción electrónica.`,
    "help_42": `11. Mi envío aparece como “Expirado”, ¿Qué debo hacer?`,
    "help_43": `“Expirado” significa que su paquete podría experimentar una condición inusual. No hay nueva información por el transportista desde hace tiempo (sobre 30 días). Si nunca recibió su paquete, por favor contacte al remitente, a fin de que puedan iniciar una investigación con el transportista en el país de origen.`,
    "help_44": `12. ¿Por qué mi paquete fue entregado en una dirección errónea?`,
    "help_45": `Aun cuando el manejo postal se hace en su mayoría de forma automática, existen labores hechas por humanos, lo que explica este tipo de errores en la entrega. Por favor, vaya al sitio web del transportista para requerir asistencia directa.`,
    "help_46": `7. ¿Por qué mi envío se encuentra en estado de “Alerta”? ¿Qué significa?`,
    "help_47": `Esto significa que su paquete ha presentado una excepción, lo que pudo ser causado por muchas razones. Las más comunes son: devuelto al remitente por dirección incompleta, problemas con la liberación aduanera, bienes restringidos, bienes prohibidos, dañado o extraviado, entre otros. `,
    "help_48": `Tenga la amabilidad de contactarse con el transportista para mayor información.`,
    "help_49": `14. ¿Qué debería hacer si mi paquete requiere liberación aduanera?`,
    "help_50": `La liberación aduanera es obligatoria para todo envío internacional. Puede contactar al transportista a fin de saber cómo se realiza la liberación aduanera. Otra alternativa es contactar al remitente, en caso que sea requerido algún documento para el pago de impuestos. Generalmente, los paquetes pequeños no requieren pago de impuestos, pero la mayoría de los envíos de alto costo pagarán impuesto. `,
    "help_51": `Por favor, sea consciente de ello cuando esté esperando un paquete internacional.`,
    "help_52": `15. Mi envío aparece como “No entregado”, ¿qué debo hacer?`,
    "help_53": `Esto significa que se ha intentado entregar su paquete sin éxito. El mismo puede ser devuelto al remitente si nadie está presente para recibirlo o después de varios intentos de entrega.`,
    "help_54": `Comúnmente, los operadores postales retendrán su paquete por 2 semanas más, desde la fecha de arribo.`,
    "help_55": `Si falla en recibir el paquete, necesita contactarse al transportista local para obtener mayores instrucciones sobre qué hacer.`,
    "help_56": `16. ¿Podrá ser entregado satisfactoriamente mi paquete si la dirección está incompleta?`,
    "help_57": `Sí, bajo circunstancias normales, lo hará, pero la entrega puede demorarse. El reparto eficiente depende de cuán precisa, completa y clara sea la dirección.`,
    "help_58": `Los paquetes son clasificados y manejados varias veces durante el transporte antes de que llegue a sus manos. Si la dirección está incompleta o incorrecta, el paquete puede ser enviado a un centro de distribución erróneo y, por ello, retrasarse. Aún peor, si no hay suficientes detalles en la dirección, su paquete será marcado como “imposible de entregar” o “dirección incompleta – devuelto al remitente”`,
    "help_59": `17. Mi envío aparece como “Para retiro”, ¿dónde debería hacerl`,
    "help_60": `Esto significa que su paquete ha llega a su país y está programado para entrega o ha llegado a una oficina local. Contacte al transportista directamente y obtenga instrucciones de dónde retirarlo.`,
    "help_61": `18. Mi paquete aparece como “En tránsito” de hace tiempo. ¿Dónde está exactamente?`,
    "help_62": `Tenga en mente que los envíos internacionales toman tiempo. Si el remitente no usó Speedpost o Correo Expreso, el Correo/Paquete Certificado o ePacket tardará casi el mismo tiempo que un envío ordinario. La única diferencia es que son rastreables y, por ello, más seguros.\nPara el correo certificado, normalmente toma alrededor de 3 a 4 semanas para países lejanos, 1 a 3 semanas para ePacket, y 1-3 semanas para EMS. Esto dependerá en gran medida de lo que tome la liberación aduanera.`,
    "help_63": `19. La información de seguimiento dice “se ha dejado aviso”, ¿por qué no la he recibido?`,
    "help_64": `La carta de aviso es una hoja pequeña que el repartidor deja en su casilla o bajo su puerta. Si no la ha encontrado, la forma más simple es contactar al transportista a través de su centro de atención al cliente y pedirles más información o dónde ir a buscar el paquete.`,
    "help_65": `20. Mi envío aparece como “Entregado”, pero nunca lo recibí. ¿Qué debo hacer?`,
    "help_66": `Bajo circunstancias normales, esto significa que su paquete fue entregado exitosamente. Si usted no lo ha recibido, es posible que su envío fue “Entregado” en su casilla de correo, o alguien más lo recibió por usted.\nPara obtener más detalles de la entrega, le recomendamos que contacte a su transportista local con el mismo número de seguimiento, o pida ayuda al remitente, a fin de asegurarse que la dirección de envío sea la suya.`,
    "help_67": `21. He comprador algo en China. ¿Por qué el país de origen dice “Estado Unidos”?`,
    "help_68": `Existen múltiples compañías disponibles para los comerciantes chinos, especialmente en la industria del eCommerce. Por ejemplo, existe Singapore Post/PostNL/DHL eCommerce packet, entre otros.`,
    "help_69": `Cuando compra algo en línea, el vendedor elegirá el método de envío que crea más adecuado para su paquete, lo que depende de muchos factores, tales como el país de destino, tarifas de envío, ítems especiales, etc.\nNuestro sistema puede detectar automáticamente el país de origen y destino, de acuerdo a la información de rastro provista por el transportista.`,
    "help_70": `22. Mi paquete fue entregado en una dirección errónea; ¿puede reenviarlo a la dirección correcta?`,
    "help_71": `Si ha confirmado que el paquete fue entregado a otra persona, por favor contacte al remitente directamente y confirme si la dirección fue escrita correctamente. Si lo fue, puede llamar al transportista local para aclarar el problema.`,
    "help_72": `Por favor tenga presente que track.a2a56.com no hace entregas. Le ofrecemos este sitio como una herramienta de rastreo y no poseemos ninguna información adicional a la mostrada en nuestra página.`,
    "delivered": `Entregado`,
    "in_transit": `ENVÍO INICIADO`,
    "expired": `Expirado`,
    "alert": `Alerta`,
    "not_found": `No se pudo encontrar el paquete`,
    "locate": `LOCALIZAR`,
    "terms_of_use": `Condiciones de uso`,
    "copyright01": `Copyright`,
    "about": `Acerca de`,
    "support": `Apoyo`,
    "comment": `Comentario`,
    "help01": `Ayuda`,
    "about02": `¿Quiénes somos?`,
    "contact_01": `a2a56 es una plataforma de rastreo que proporciona información de múltiples mensajeros / transportistas internacionales.`,
    "contact_02": `a2a56 no está a cargo de la entrega de su pedido. Para paquetes y paquetes perdidos o dañados y cualquier otro problema de entrega, debe comunicarse con el remitente o el lugar donde realizó su pedido o con el transportista / mensajero que está a cargo de entregar su paquete.`,
    "contact_form_01": `Cuéntanos que opinas para que podamos proporcionarte asistencia personalizada en 24 horas.`,
    "contact_form_02": `Nombre y apellidos`,
    "contact_form_03": `Ingresa tu correo@ejemplo.com`,
    "contact_form_04": `Información sobre un paquete`,
    "contact_form_05": `Soluciones de API & webhooks`,
    "contact_form_06": `Otro`,
    "contact_form_07": `Tu mensaje`,
    "contact_form_08": `Enviar`,
    "contact_form_09": `Selecciona un tema:`,
    "contact_03": `¿Necesitas ayuda?`,
    "contact_04": `Visita nuestro centro de ayuda y busca información sobre toda clase de temas de los cuales tengas duda.`,
    "no_results_header": `No existen resultados`,
    "no_results_text": `Introduzca el número de seguimiento de su paquete.`,
    "hy": `Hola`,
    "how_can_we_help_you": `¿En qué podemos ayudarte?`,
    "search_in_our": `Busque en nuestra base de conocimientos o navegue por las preguntas a continuación.`,
    "faq": "Preguntas frecuentes",
    "help_center_button": `Centro de ayuda de a2a56`,
    "table_of_contents": `Tabla de contenido`,
    "what_is": `¿Qué es`,
    "31_track": `a2a56?`,
    "what_is_description": `Es una plataforma de servicios de información logística líder en América Latina, que brinda servicios de flujo de información personalizados para logística y entrega urgente a América Latina.`
}