import { TrackingNumberResultHash } from '../screens/ResultScreen';
import { TrackingEvent, TrackingResult } from '../types';
import * as XLSX from 'xlsx';

function createXlsx(data: any[][]) {
  // create new workbook
  var wb = XLSX.utils.book_new(); // converts an array of arrays into a worksheet.
  var ws = XLSX.utils.aoa_to_sheet(data); // add worksheet to workbook under name Sheet1
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // save workbook to file export.xlsx
  XLSX.writeFile(wb, 'export.xlsx');
}

export function arraysEqual(a: any[], b: any[]): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

type TrackingResultNonEventInfo = Pick<
  TrackingResult,
  | 'trackingNumber'
  | 'receivedBy'
  | 'receivedById'
  | 'carrier'
  | 'from'
  | 'to'
  | 'delivered'
  | 'deliveredAt'
> & { shipmentStatus: TrackingResult['status'] };

type ExportableEventRow = TrackingEvent & TrackingResultNonEventInfo;

export function downloadTrackingData(
  trackingNumberResultHashList: TrackingNumberResultHash[],
  format: 'csv' | 'xlsx',
  mostRecent: boolean,
) {
  if (trackingNumberResultHashList.length === 0) {
    return;
  }

  const eventInfoPairs: {
    events: TrackingEvent[];
    info: TrackingResultNonEventInfo;
  }[] = trackingNumberResultHashList.map(tnrh => {
    if (
      !tnrh.trackingResult ||
      tnrh.fetchStatus === 'error' ||
      tnrh.trackingResult.data.events.length === 0
    ) {
      return {
        events: [
          {
            datetime: '',
            location: '',
            status: '',
            status_code: '',
          },
        ],
        info: {
          trackingNumber: tnrh.trackingNumber,
          receivedBy: '',
          receivedById: '',
          carrier: null,
          from: '',
          to: '',
          delivered: false,
          deliveredAt: '',
          shipmentStatus: 'not-found',
        },
      };
    }

    const {
      data: { events },
      status,
      ...info
    } = tnrh.trackingResult;

    return {
      events,
      info: { ...info, shipmentStatus: tnrh.trackingResult.status },
    };
  });

  const exportableEventRowMatrix: ExportableEventRow[][] = eventInfoPairs.map(
    eip => {
      const { events, info } = eip;
      const filteredEvents = mostRecent ? events.slice(0, 1) : events;
      const exportableEventRows: ExportableEventRow[] = filteredEvents.map(e => {
        return {
          ...e,
          ...info,
        };
      });

      return exportableEventRows;
    },
  );

  const exportableEventRows: ExportableEventRow[] =
    exportableEventRowMatrix.flatMap(a => a);

  const headers: Array<keyof TrackingResultNonEventInfo | keyof TrackingEvent> =
    [
      'carrier',
      'datetime',
      'delivered',
      'deliveredAt',
      'from',
      'location',
      'receivedBy',
      'receivedById',
      'shipmentStatus',
      'status',
      'status_code',
      'to',
      'trackingNumber',
    ];

  const contentRows = exportableEventRows.map(eer => {
    return headers.map(h => eer[h]);
  });
    
  const rows = [...[headers], ...contentRows];

  if (format === 'csv') {
    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    return window.open(encodedUri);
  }

  return createXlsx(rows);
}
