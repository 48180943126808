const ClockSvg = () => {
  return ( 
    <svg width="77" height="70" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.7006 10.7657C28.3928 10.7657 28.0851 10.7401 27.803 10.6888V14.3021C27.803 14.789 28.2133 15.199 28.7006 15.199C29.1879 15.199 29.5982 14.789 29.5982 14.3021V10.6888C29.3161 10.7401 29.0084 10.7657 28.7006 10.7657Z" fill="#013177"/>
      <path d="M28.7006 11.6626C25.623 11.6626 23.1353 9.15125 23.1353 6.10175C23.1353 3.05225 25.6486 0.540894 28.7006 0.540894C31.7782 0.540894 34.2659 3.05225 34.2659 6.10175C34.2659 9.15125 31.7782 11.6626 28.7006 11.6626ZM28.7006 2.3091C26.6232 2.3091 24.9305 4.00041 24.9305 6.07612C24.9305 8.15183 26.6232 9.84315 28.7006 9.84315C30.778 9.84315 32.4706 8.15183 32.4706 6.07612C32.4706 4.00041 30.778 2.3091 28.7006 2.3091Z" fill="#E67008"/>
      <path d="M17.3392 19.2479L13.8256 14.5071C13.5179 14.0971 12.9536 14.0202 12.5689 14.3277L8.41418 17.4028C8.209 17.5566 8.08077 17.7616 8.05513 17.9922C8.02948 18.2229 8.08077 18.4791 8.23465 18.6585L11.7482 23.3993C11.9278 23.6299 12.2099 23.7581 12.4664 23.7581C12.6459 23.7581 12.8511 23.7068 13.0049 23.5787L17.1597 20.5036C17.3649 20.3498 17.4674 20.1448 17.5187 19.9142C17.5444 19.6835 17.4931 19.4529 17.3392 19.2479Z" fill="#E67008"/>
      <path d="M49.0641 17.4029L44.9093 14.3278C44.7298 14.174 44.4733 14.1228 44.2425 14.1484C44.0117 14.174 43.7809 14.3021 43.6527 14.5071L40.139 19.248C39.8313 19.658 39.9339 20.2218 40.3186 20.5036L44.4733 23.5788C44.6272 23.7069 44.8068 23.7581 45.0119 23.7581C45.0632 23.7581 45.0889 23.7581 45.1402 23.7581C45.371 23.7325 45.6018 23.6044 45.73 23.3994L49.2436 18.6586C49.5514 18.2742 49.4745 17.7104 49.0641 17.4029Z" fill="#E67008"/>
      <path d="M48.3973 21.913C43.1398 16.6597 36.1382 13.7639 28.7007 13.7639C21.2631 13.7639 14.2616 16.6597 9.00403 21.913C3.74646 27.1663 0.848389 34.1622 0.848389 41.5938C0.848389 49.0253 3.74646 56.0212 9.00403 61.2746C14.2616 66.5279 21.2631 69.4237 28.7007 69.4237C36.1382 69.4237 43.1398 66.5279 48.3973 61.2746C53.6549 56.0212 56.553 49.0253 56.553 41.5938C56.553 34.1622 53.6549 27.1663 48.3973 21.913Z" fill="#E67008"/>
      <path d="M48.7519 44.8503C50.5569 33.7869 43.0443 23.3563 31.972 21.5527C20.8997 19.7492 10.4607 27.2557 8.65568 38.3191C6.85069 49.3825 14.3633 59.8131 25.4356 61.6167C36.5078 63.4202 46.9469 55.9137 48.7519 44.8503Z" fill="white"/>
      <path d="M28.7005 62.7866C17.0056 62.7866 7.49072 53.2793 7.49072 41.5939C7.49072 29.9084 17.0056 20.4011 28.7005 20.4011C40.3954 20.4011 49.9103 29.9084 49.9103 41.5939C49.9103 53.2793 40.3954 62.7866 28.7005 62.7866ZM28.7005 22.1949C18.0059 22.1949 9.28599 30.8822 9.28599 41.5939C9.28599 52.2799 17.9802 60.9928 28.7005 60.9928C39.3952 60.9928 48.115 52.3055 48.115 41.5939C48.115 30.9078 39.3952 22.1949 28.7005 22.1949Z" fill="#013177"/>
      <path d="M28.7006 20.4011C28.2133 20.4011 27.803 20.8111 27.803 21.298V26.7307C27.803 27.2176 28.2133 27.6277 28.7006 27.6277C29.1879 27.6277 29.5982 27.2176 29.5982 26.7307V21.298C29.5982 20.8111 29.1879 20.4011 28.7006 20.4011Z" fill="#013177"/>
      <path d="M28.7006 55.5601C28.2133 55.5601 27.803 55.9701 27.803 56.457V61.8897C27.803 62.3766 28.2133 62.7866 28.7006 62.7866C29.1879 62.7866 29.5982 62.3766 29.5982 61.8897V56.457C29.5982 55.9701 29.1879 55.5601 28.7006 55.5601Z" fill="#013177"/>
      <path d="M49.0127 40.6969H43.5756C43.0883 40.6969 42.678 41.1069 42.678 41.5938C42.678 42.0807 43.0883 42.4907 43.5756 42.4907H49.0127C49.5 42.4907 49.9103 42.0807 49.9103 41.5938C49.9103 41.0813 49.5 40.6969 49.0127 40.6969Z" fill="#013177"/>
      <path d="M13.8257 40.6969H8.3886C7.90132 40.6969 7.49097 41.1069 7.49097 41.5938C7.49097 42.0807 7.90132 42.4907 8.3886 42.4907H13.8257C14.313 42.4907 14.7233 42.0807 14.7233 41.5938C14.7233 41.0813 14.313 40.6969 13.8257 40.6969Z" fill="#013177"/>
      <path d="M40.9854 28.4221C40.7546 28.2171 40.4211 28.1402 40.1134 28.2427C32.4963 30.7028 28.0594 40.8251 27.8799 41.2351C27.726 41.5939 27.803 42.0039 28.1107 42.2602C28.2903 42.4139 28.4954 42.4908 28.7263 42.4908C28.8801 42.4908 29.0084 42.4652 29.1623 42.3883C29.2648 42.337 31.8295 40.9532 34.625 38.6725C38.4207 35.5461 40.6776 32.3941 41.3188 29.2678C41.3188 28.9602 41.2162 28.6271 40.9854 28.4221Z" fill="#F7941E"/>
      <path d="M75.9675 44.3102L70.6074 38.9544C70.4279 38.775 70.197 38.6981 69.9662 38.6981C69.7354 38.6981 69.5046 38.8006 69.3251 38.9544L51.3467 56.9182L43.2167 48.7948C42.8577 48.436 42.2935 48.436 41.9344 48.7948L37.0615 53.6637C36.7025 54.0225 36.7025 54.5863 37.0615 54.945L51.1928 69.065C51.3724 69.2443 51.6032 69.3212 51.834 69.3212C52.0648 69.3212 52.2956 69.2443 52.4752 69.065L75.9675 45.5915C76.3009 45.2328 76.3009 44.6434 75.9675 44.3102Z" fill="#FBB040"/>
      <path d="M51.5775 56.8927C51.5006 56.9183 51.4493 56.9183 51.398 56.9439L43.2167 48.7692C42.8577 48.4104 42.2935 48.4104 41.9344 48.7692L37.0615 53.6381C36.7025 53.9969 36.7025 54.5607 37.0615 54.9194L51.1159 68.9625C51.2441 69.0906 51.398 69.1675 51.5775 69.2188V56.8927Z" fill="#F7941E"/>
    </svg>
  )}
  
  export default ClockSvg;
  