import { Box, HStack, Image, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box width="70%" display="flex" flexDir="column">
      <HStack justifyContent="space-between" width="100%">
        <Image
          source={{
            uri: `${process.env.PUBLIC_URL}/icons/logo_donex_transparente.png`,
          }}
          alt="Sobre despacho"
          width="185px"
          height="74px"
        />
        <HStack width="652px" justifyContent="space-between">
          <VStack>
            <Box>
              <Text
                textAlign="left"
                fontStyle="italic"
                fontSize="18px"
                textTransform="uppercase"
                fontFamily="Segoe UI"
                fontWeight="semibold">
                {t('about')}
              </Text>
              <Box
                height="3px"
                width="50px"
                backgroundColor="#ff9300"
                mb="20px"
              />
            </Box>

            {/*@ts-ignore */}
            <Link to="/about" style={{ textDecoration: 'none' }}>
              {/*@ts-ignore */}
              <Pressable p={0} m={0}>
                {({ isHovered }) => {
                  return (
                    <Text
                      fontSize="16px"
                      fontFamily="Segoe UI"
                      color={isHovered ? '#293d8b' : '#121212'}
                      textAlign="left"
                      letterSpacing="0px"
                      mb="10px">
                      {t('about')}
                    </Text>
                  );
                }}
              </Pressable>
            </Link>

            {/*@ts-ignore */}
            <Link to="/security_policy" style={{ textDecoration: 'none' }}>
              {/*@ts-ignore */}
              <Pressable p={0} m={0}>
                {({ isHovered }) => {
                  return (
                    <Text
                      fontSize="16px"
                      fontFamily="Segoe UI"
                      color={isHovered ? '#293d8b' : '#121212'}
                      textAlign="left"
                      letterSpacing="0px"
                      mb="10px">
                      {t('security policy')}
                    </Text>
                  );
                }}
              </Pressable>
            </Link>
          </VStack>
          <VStack>
            <Box>
              <Text
                textAlign="left"
                fontStyle="italic"
                fontSize="18px"
                textTransform="uppercase"
                fontFamily="Segoe UI"
                fontWeight="semibold">
                {t('support')}
              </Text>
              <Box
                height="3px"
                width="50px"
                backgroundColor="#ff9300"
                mb="20px"
              />
            </Box>

            {/*@ts-ignore */}
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              {/*@ts-ignore */}
              <Pressable p={0} m={0}>
                {({ isHovered }) => {
                  return (
                    <Text
                      fontSize="16px"
                      fontFamily="Segoe UI"
                      color={isHovered ? '#293d8b' : '#121212'}
                      textAlign="left"
                      letterSpacing="0px"
                      mb="10px">
                      {t('contact us')}
                    </Text>
                  );
                }}
              </Pressable>
            </Link>
          </VStack>
          <VStack>
            <Box>
              <Text
                textAlign="left"
                fontStyle="italic"
                fontSize="18px"
                textTransform="uppercase"
                fontFamily="Segoe UI"
                fontWeight="semibold">
                {t('help_center')}
              </Text>
              <Box
                height="3px"
                width="50px"
                backgroundColor="#ff9300"
                mb="20px"
              />
            </Box>

            {/*@ts-ignore */}
            <Link to="/help" style={{ textDecoration: 'none' }}>
              {/*@ts-ignore */}
              <Pressable p={0} m={0}>
                {({ isHovered }) => {
                  return (
                    <Text
                      fontSize="16px"
                      fontFamily="Segoe UI"
                      color={isHovered ? '#293d8b' : '#121212'}
                      textAlign="left"
                      letterSpacing="0px"
                      mb="10px">
                      {t('help_center')}
                    </Text>
                  );
                }}
              </Pressable>
            </Link>
          </VStack>
        </HStack>
      </HStack>
      <VStack width="100%" mt="40px" alignItems="center">
        <Box
          width="100%"
          backgroundColor="#d9d9d9"
          borderRadius="4px"
          height="1px"
        />
        <Box mt="40px">
          <Text pb="40px" color="#757575" fontSize="14px">
          Don Express co,.Limited Copyright © {new Date().getFullYear()}. Todos los derechos reservados.
          </Text>
        </Box>
      </VStack>
    </Box>
    // <VStack flex={1} maxW={'100%'}>
    //   <ContactUsBanner />
    //   <Box bg={'white.600'} flex={1}>
    //     <HStack justifyContent={'space-evenly'} my={3} flex={1}>
    //       <Box ml={5}>
    //         <Image
    //           resizeMode="contain"
    //           src={`${process.env.PUBLIC_URL}/logo.png`}
    //           size="md"
    //           width={'15vh'}
    //           alt="31 track logo"
    //         />
    //       </Box>
    //       <Box maxW={'xs'}>
    //         <Text>{t('about Us')}</Text>
    //         <Text>{t('agreement')}</Text>
    //         <Text>{t('copyright')}</Text>
    //         <Text>{t('Privacy Policy')}</Text>
    //       </Box>
    //       <Box maxW={'xs'}>
    //         <Text>{t('contact us')}</Text>
    //         <Text>{t('Feedback')}</Text>
    //       </Box>
    //       <Box maxW={'xs'}>
    //         <Text>{t('help')}</Text>
    //       </Box>
    //     </HStack>
    //   </Box>
    // </VStack>
  );
};

export default Footer;
