export const translation = {
  landing_text: '获取快递和邮政服务跟踪信息',
  landing_header: `源自拉丁美洲的物流\n查询平台 track.a2a56.com`,
  query: '查询',
  'about Us': '关于我们',
  'contact us': '联系我们',
  get_in_touch: '与我们联系',
  get_in_touch_description:
    'track.a2a56.com 是拉丁美洲领先的物流信息服务平台，为去向拉丁美 洲的物流和快递定制信息流服务。 如需更多个性化服务或建议，请联 系我们!',
  contact: '联系一下',
  help: '帮助中心',
  help_center: '帮助中心',
  'security policy': '安全政策',
  'Provide smooth query': '提供顺畅的查询',
  content_1:
    '自动识别所有合作运输商，全程跟踪物流信息，无需选择物流商，一步操作获得完整准确的轨迹信息，所有节点清晰呈现。',
  'Focus on the Latin American market': '聚焦拉丁美洲市场',
  content_2:
    '已与拉丁美洲主流物流商合作，合作运输商覆盖拉丁美洲主流国家。如需跟踪更多运输商包裹, 可向客服反馈，我们将尽力支持用户需要的运输商。',
  'Customer service across time differences': '跨时差客户服务',
  content_3:
    '拥有一支专业的多语种客户服务与中外技术研发团队，致力于为用户快速解决问题，为用户提供6*24 小时在线服务。',
  'User privacy data protection': '用户隐私数据保护',
  content_4:
    '我们期待与电商平台，卖家，物流商保持健康、长期的合作关系。遵守《通用数据保护条例》，保护用户数据和隐私。',
  advantage: '优点',
  world_text_1: '拉丁美洲领先的物流数字化服务及管理平台,',
  world_text_2: '致力于建立更独立，更智能的物流信息解决方案。',
  agreement: '许可协议',
  copyright: '版权声明',
  'Privacy Policy': '隐私政策',
  Feedback: '建议反馈',
  contact_us_banner_text:
    '拉丁美洲领先的物流信息综合服务平台，为您定制拉丁美洲物流、快递的信息流服务。如需更多定制服务或提出建议，欢迎联系我们',
  'Enter tracking number': '输入追踪号码',
  about_1:
    'track.a2a56.com为广大的电子商务企业提供实用的货运跟踪服务与物流流数据服务。',
  about_2: '它成立于墨西哥，专注于拉丁美洲市场的电子商务和跨境物流领域。',
  about_3:
    '在track.a2a56.com，我们认为跟踪信息是吸引客户的关键，改善体验的最佳方式是在一个地方提供跟踪信息，而无需再次主动选择Carriers网站。 我们认为无论您是买方，物流供应商还是卖方，所有电子商务参与者都适用访问我们网站的信息。',
  about_4:
    '我们希望您喜欢使用track.a2a56.com的包裹跟踪服务，我们乐于尽可能地为您提供最佳体验。如果您有任何问题或意见，请毫不犹豫地与我们联系！',
  security_1:
    '感谢您访问track.a2a56.com网站、移动应用程序、设备及其他相关软件。本政策适用于提供track.a2a56.com产品和服务（包括移动端、网站、小程序等）,以下称“我们”或“track.a2a56.com”。 我们非常尊重及认真对待您的上网隐私，并希望您了解我们的隐私政策以保护您的个人信息。 此隐私条款也适用于我们所有服务及产品，无论是通过a2a56.com主站、移动应用程序或移动端网站。',
  security_2: '隐私政策具体解释如下：',
  security_3: '如果您继续访问我们的网站，意味着您接受本隐私政策中所包含条款：',
  security_4: '定义',
  security_5:
    '个人数据：指可以直接或间接识别的自然人的任何信息，特别是通过引用标识符，如姓名、标识号、位置数据或在线标识符。',
  security_6:
    '处理：指对个人数据执行的任何操作，如收集、记录、组织、结构、存储、适应或任何类型的披露或其他用途。',
  security_7: '注册我们的服务系统',
  security_8:
    '我们的网站提供获取时事通讯的选项。要使用此服务，您必须设置一个帐户。关于账户的注册及其后续使用，我们处理：',
  security_9:
    '注册提供的信息（如您的姓名和电子邮件地址） 与帐户登录功能相关的信息（例如登录和密码详细信息） 您发送的信息（例如通过电子邮件或网站信息表格）',
  security_10: '我们将处理您所提供的个人资料：',
  security_11:
    '登录时确认您的身份\n通过网站为您提供您所请求的服务与信息\n管理您的帐户\n与您沟通\n广告和分析',
  security_12:
    '只要您的用户帐户被使用,您的个人数据在以下特定服务中没有例外情况的情况下会被保留。删除您的帐户后，您的个人数据将被阻止并在下一个数据库清理时删除。法定存储义务或因服务中的不当行为或付款问题而可能采取的法律行动，可能会导致您的个人数据保留时间更长。在这种情况下，我们将相应地通知您。',
  security_13: '通讯',
  security_14:
    '通过您的电子邮件地址，您可以订阅我们的时事通讯，如果您同意接收此类新闻稿，您可以订阅我们的新闻稿，该新闻稿为您提供了有关您的套餐的最新更新，并且还可能包含主题特定的广告。',
  security_15:
    '如果您以前没有持有帐户，您还可以在订购新闻稿期间完成注册。 您可以通过每个新闻稿中提供的链接选择退出本服务。',
  security_16: '在线广告',
  security_17:
    '本网站使用在线广告服务向您展示符合您兴趣的广告。相应的广告标有提供商的名称，例如"Google-Ad"。此外，这项服务有助于我们改进我们的网站，使您的访问更有趣。为此，我们会收集有关您统计信息，由提供商处理。',
  security_18: '谷歌广告词',
  security_19:
    '我们使用 Google Adwords 在我们的网站和第三方网站上向我们网站以前的访问者做广告。谷歌广告由谷歌有限责任公司提供，美国94043加利福尼亚州1600剧场百汇山景（"谷歌"）。',
  security_20:
    '广告根据存储在 Google 放在您计算机上的 Cookie 中的信息显示。文本文件包括您访问我们网站时的信息，特别是在此或第三方网站后续访问过程中为特定产品推荐而读出的产品视图。Cookie 包含一个随机别名。如果您在特定时间内访问我们的网站并查看我们的产品，Google 可以通过别名识别您。我们或 Google 不会将此信息与您的个人数据合并，也不会将您的个人数据透露给任何第三方。您可以通过单击以下链接https://www.google.com/ads/preferences并配置您的首选项，防止在 Google 放置的 Cookie 中存储和使用信息。',
  security_21:
    '有关 Google 隐私政策的更多信息，请参阅https://privacy.google.com/和https://www.google.com/policies/privacy/。',
  security_22: '谷歌广告话和Facebook自定义受众',
  security_23:
    '在本网站，我们使用谷歌公司提供的AdWords的再营销或"类似受众"功能，美国94043加利福尼亚州1600剧场百汇山景，以下简称"谷歌"。',
  security_24:
    'Google 使用 Cookie/跟踪技术，即存储在您计算机上的文本文件，允许我们分析您对我们网站的使用。由 Cookie/跟踪技术生成的关于您使用本网站的信息（包括您的 IP 地址）将传输到美国的 Google 服务器并存储在该服务器上。之后，Google 将删除该 IP 地址的最后三位数字，以便不再能够将此 IP 地址与个人数据明确匹配。Google 已获得隐私保护认证，并同意遵守美国商务部发布的关于收集、使用和存储欧盟成员国个人数据的欧盟和美国之间的隐私保护条约。Google 将使用这些信息来分析您对网站的使用情况，为网站运营商创建网站活动报告，并提供与使用网站和互联网相关的其他服务。此外，如果按照法律规定',
  security_25:
    '如果按照法律规定要求将此信息传输给第三方，或者第三方代表 Google 处理数据，则 Google 可能会将此信息传输给第三方。',
  security_26:
    '第三方提供商（包括 Google）在互联网上的网站上投放广告。第三方提供商（包括 Google）使用存储的 Cookie/跟踪信息，根据用户以前访问本网站的用户投放广告。',
  security_27: '有关对搜索行为进行匿名分析的其他信息，请参阅:',
  security_28: 'https://support.google.com/analytics/answer/6004245?hl=en',
  security_29: 'https://www.google.com/policies/technologies/ads/',
  security_30:
    '您可以随时反对为再营销目的收集和存储数据 - 此异议将在未来有效，但不是追溯性 - 通过停用 Google 中基于兴趣的广告或停用网站中的服务网络广告倡议。注意：在这种情况下，您可能不能再使用本网站的所有功能。使用本网站即表示您同意以上述方式和目的处理 Google 收集的关于您的数据。',
  security_31:
    '此外，本网站使用重定向标签和自定义受众的Facebook公司，美国94304加利福利亚州帕洛阿尔托，1601南加州大道，以下简称"脸书"。',
  security_32:
    '当您访问我们的网页时，再营销标签将在您的浏览器和 Facebook 服务器之间建立直接连接。这样，Facebook 会了解到您已使用您的 IP 地址访问过我们的网页。这将使 Facebook 能够将您访问我们的网页与您的用户帐户相匹配。以这种方式获得的信息可用于显示 Facebook 广告。请注意，作为网页提供商，我们没有收到任何有关 Facebook 传输的数据内容及其使用的信息。',
  security_33:
    '关于自定义受众的使用，我们想指出，Facebook 和第三方使用 Cookie、网络信标或类似技术来收集或收集本网站上的信息。根据获得的数据，我们可以提高 Facebook 活动的效率，例如，安排仅向网站访问者展示内容或广告。以这种方式收集的数据将被加密并传输到 Facebook，并且对我们来说是匿名的，例如，我们看不到个人用户的个人数据。',
  security_34: '第三方内容和社交媒体插件',
  security_35:
    '我们使用以下社交媒体插件：Facebook。这允许您与此类服务进行沟通，并喜欢或评论我们的网站。社交媒体插件支持您的设备与社交媒体提供商的服务器之间的直接通信，允许社交媒体提供商与您通信并收集有关您浏览我们网站的信息。此处理基于《数据保护条例》的第 6 章 1b条。代表我们改善您的网站体验和优化我们的服务的合法利益。',
  security_36: '无论您是否在提供商处拥有帐户，个人数据的传输都会发生。',
  security_37:
    '请注意，我们既无法控制相应插件提供商收集的个人数据的范围，也不知道处理的目的或您的个人数据将保留的期限。',
  security_38:
    '有关提供商在运营过程中处理您的个人数据的更多信息，请参阅其各自的隐私政策。此外，您将获得有关您权利和隐私设置的进一步信息。',
  security_39: 'a） Facebook 社交插件',
  security_40:
    '我们使用所谓的社交插件（"插件"）的社交网站facebook.com提供Facebook公司，美国94304加利福利亚州帕洛阿尔托，1601南加州大道（"Facebook"）。插件可以通过 Facebook 的徽标之一（蓝色磁贴上的白色"f"或"点赞"的标记）或附加文本"Facebook 社交插件"来标识。Facebook 社交插件列表及其外观可通过：https://developers.facebook.com/docs/plugins/访问。 ',
  security_41: `如果用户使用此类插件访问其中一个网站，则用户的浏览器会直接连接到 Facebook 的服务器。该插件及其内容直接在 Facebook 的服务器上提供，并存在用户的浏览器的网站中。`,
  security_42: `数据采集的原因和范围，以及有关 Facebook 处理和使用的数据的信息，以及用户在这方面的权利以及保护用户隐私的设置选项，请参阅以下选项：https://www.facebook.com/policy.php。`,
  security_43: `如果用户是 Facebook 的成员，并且不希望 Facebook 通过此主页收集个人数据，并将这些数据与存储在 Facebook 上的数据链接在一起，则用户需要先从 Facebook 注销，然后再进入此主页。`,
  security_44: `用户还可以使用用户浏览器的加载项（例如"Facebook 阻止程序"）阻止 Facebook 的插件。`,
  security_45: `未成年人上网隐私安全`,
  security_46: `我们非常尊重未成年人隐私。 我们不会在明知的情况下故意收集18岁以下未成年人的个人信息。如果您继续访问或者使用本站服务，意味着您保证已年满18周岁，或在家长或监护人的监督下使用本站服务。\n如果您未满18岁，请不要向我们提交任何个人信息，并且在使用本网站时依靠家长或监护人协助您。`,
  security_47: `欧盟国家访客补充条款`,
  security_48: `本隐私政策所包含的这部分，仅适用于使用我们服务及产品的来自欧盟成员国的情况访客，只做为补充本隐私政策信息。\n受适用法律的限制，您有权反对或要求限制您处理您的个人信息，并要求查阅、移植、更正和删除您的个人信息。 使用您的信息是基于您的同意，您也可以随时撤销此同意，而不会影响撤回前根据同意进行的处理的合法性。\n请通过我们的电子邮件a2a56.com联系我们提交请求。`,
  security_49: `安全性`,
  security_50: `我们制定了合理的安全措施，以防止我们控制下的个人数据丢失、误用和更改。例如，我们的安全和隐私政策会根据需要定期进行审查和增强，只有经过授权的人员才能访问个人数据。虽然我们无法确保或保证信息永远不会丢失、误用或更改，但我们会采取一切合理努力来防止信息丢失、误用或更改。`,
  security_51: `您应该记住，通过互联网提交信息从来就不是完全安全的。我们不能保证您通过互联网传输的信息的安全性，任何此类提交风险由您自行承担。`,
  security_52: `数据保留`,
  security_53: `我们努力使我们对您的个人数据的处理活动尽可能有限。如果本政策没有规定的特定保留期，则您的个人数据仅在我们需要满足我们收集目的时保留，如果适用，则只要法定保留要求要求。`,
  security_54: `您的权利`,
  security_55: `根据适用于您的法律，您可能有权行使以下部分或全部权利：`,
  security_56: `要求 （i） 有关您的个人数据是否被保留的信息，以及 （ii） 对保留的您的个人数据的访问和/或复制，包括处理目的、相关个人数据的类别、数据接收者以及潜在的保留期;`,
  security_57: `请求更正、删除或限制您的个人数据，例如：（i） 个人数据不完整或不准确，（ii） 不再需要用于收集个人数据的目的，或 （iii） 批准的依据被撤销;`,
  security_58: `拒绝提供且不影响在撤回之前进行的数据处理活动，并随时撤回关于处理您的个人数据的您的批准决定;`,
  security_59: `依据您的具体情况为由，反对处理您的个人数据。在这种情况下，请向我们提供有关您具体情况的信息。在评估您提供的事实后，我们将停止处理您的个人数据，或为您提供我们令人信服的合法理由进行持续处理;`,
  security_60: `有关处理您的个人数据的任何潜在侵犯，请联系我们：`,
  security_61: `要求 ：i） 以结构化、常用和机器可读的格式接收您提供给我们的与您有关的个人数据，以及 （ii） 从我们这边不受阻碍地将这些数据传输给其他控制器;在技术上可行的情况下，您有权将个人数据直接从我们传送给另一个控制器;`,
  security_62: `不受任何自动决策的约束，包括分析（基于自动处理的数据处理自动决策，以便评估），这些决策对您产生法律影响或对您产生类似的影响。`,
  security_63: `您可以通过使用info@a2a56.com联系我们，行使上述权利或向我们提出任何问题。`,
  security_64: `我们保留不定时更新我们的网站来修改本政策的权利。请定期访问网站，并查看我们各自的当前隐私政策。本政策最后更新于2021年1月.`,
  security_65: `您的选择`,
  security_66: `您始终可以选择不提供您的个人信息，尽管可能无法使用我们网站的某些特定功能。`,
  security_67: `您可以通过发邮件到 info@a2a56.com 要求：`,
  security_68: `•	更正或更新您的个人信息\n•	停止接收邮件到您的电子邮件地址\n•	停止接收短信到您的手机\n•	停用您的帐户\n•	阻止您电脑上的cookies，这或导致您无法使用网站上的特定服务`,
  security_69: `Cookies政策`,
  security_70: `＃关于cookies`,
  security_71: `Cookie是由Web服务器发送到Web浏览器的文件，通常包括唯一标识符，每次浏览器从服务器请求页面时，都可以将其发送回服务器。`,
  security_72: `Web服务器可以使用Cookie在用户浏览网站上的不同页面时识别和跟踪用户，并识别返回网站的用户。`,
  security_73: `Cookie可以是“持久性”Cookie或“会话”Cookie。持久性cookie由Web服务器发送到Web浏览器的文本文件组成，该文本文件将由浏览器存储，并且在其设置的到期日期之前保持有效（除非用户在到期日之前删除）。另一方面，会话cookie将在用户会话结束时（Web浏览器关闭时）到期。`,
  security_74: `track.a2a56.com使用Cookie和类似技术来分析趋势、管理网站、跟踪用户在站点的活动情况，并收集有关我们整个用户群的人口统计信息。 我们使用cookies出于多种目的，包括但不限于辨别用户身份，进行 session 跟踪，储存在用户本地终端上的偏好数据及访问过的网页。`,
  security_75: `＃第三方cookie`,
  security_76: `当您使用本网站时，您可能还会收到第三方cookie。`,
  security_77: `我们的广告商和服务提供商可能会向您发送cookie。他们可能会使用从您使用Cookie中获得的信息：`,
  security_78: `跨多个网站跟踪您的浏览器\n定位您可能特别感兴趣的广告。`,
  security_79: `＃阻止cookie`,
  security_80: `大多数浏览器允许您拒绝接受cookie。例如：`,
  security_81: `您可以在浏览器调低cookie使用级别。 如果您选择禁用Cookie，则可能在使用本网站某些特定功能时受限。`,
  'An error occurred while getting the data from the server': `从服务器获取数据时出错。`,
  help_1: `1. 我的包裹多久才可以收到？ `,
  help_2: `以下是通常情况下，全球邮政挂号小包或E邮宝的运输时间（注意:不包括偏远国家及地区）：`,
  help_3: `     • 第1-2天 接收/原发货地`,
  help_4: `     • 第2-3天 出口操作中心`,
  help_5: `     • 第2-4天 待海关检验/出口操作中心`,
  help_6: `包裹所在航班/船次离港后，大部分将不再更新包裹状态，直至到达目的地国家！`,
  help_7: `     • 第4-10天 抵达目的地国家港口或机场`,
  help_8: `     • 第10-15天 待海关检验/进口操作中心`,
  help_9: `     • 第15-30天 目的国内运输以及最后一英里交货`,
  help_10: `如果超过60天仍未顺利投递，包裹可能会退回给发件人，请您主动联系运输商以便确认。`,
  help_11: `当您的包裹到达目的地国家后，如果您急于拿到您的包裹，可以尝试联系您当地的运输商进行主动取货。`,
  help_12: `2. 我的包裹现在到哪里了？`,
  help_13: `对于大多数的国际挂号小包、大包以及快递服务，国际件一般没有预计到达日期，所以运输时间可能比你预期的慢，也可能比你预期的更快。由于目的国的进出口海关流程和航空公司的区别，国际航运与国内快递有很大的差异。`,
  help_14: `track.a2a56.com是第三方独立站点，专注于查询拉丁美洲市场的邮政包裹以及国际快递包裹。我们主要向您提供从相应的合作运输商官方网站合法获取跟踪数据。我们不参与直接的包裹运输和您商品的线上交易过程。`,
  help_15: `如果运输商更新物流信息track.a2a56.com也会同步跟新，你可以稍等一段时间后再尝试查询一下你的包裹。`,
  help_16: `3. 为什么我的包裹“查询不到”？`,
  help_17: `“查询不到”表示我们找不到该单号所对应包裹的任何信息。我们建议您仔细检查跟踪号是否正确，或者联系发货人（您下订单的商店）验证您的单号。`,
  help_18: `如果跟踪号是正确的，请在包裹发出至少1-2天后再查询跟踪它的详细信息。因为通常情况下，运输商还需要一些时间来进行包裹的收取及处理。`,
  help_19: `4. 我没有收到我的包裹，可以申请退款吗？`,
  help_20: `如果您有任何退款需求，我们建议你直接与购买商品的商家/发货人进行联系。`,
  help_21: `track.a2a56.com是第三方独立站点，我们只提供查询全球邮政包裹以及国际快递的包裹服务。需要特别注意的是，我们并不直接参于具体的运输事宜，也不参与在线交易。`,
  help_22: `因此，网上交易或退款相关问题只能由您所购买商品的商店来处理。`,
  help_23: `5. 如何修改包裹的收件地址？`,
  help_24: `一般情况下，如果包裹已发货，您需要立即联系当地的运输公司或通过要求您购买商品的商店来反馈您想要更改地址的请求。或者您也可以访问运输商的官方网站获得进一步的帮助。`,
  help_25: `6. 包裹停留在某个地区时，会有更新信息吗？`,
  help_26: `如果你的包裹已经有很长一段时间没有更新物流信息，这可能意味着该包裹仍在运输途中或者运输商省略了这一部分的跟踪信息。也有一些运送方式不支持全流程的物流跟踪。在这种情况下你可以直接联系发件人或者是目的国本地的运输公司来正式调查你的包裹下落。`,
  help_27: `track.a2a56.com是一个物流跟踪网站，为您提供便捷的包裹跟踪服务，但对交货过程或发货时间是没有任何影响的，关于包裹的实际运输，这是由您包裹的运输商来决定的。`,
  help_28: `7. 你可以通过跟踪号确认我的地址吗？`,
  help_29: `很抱歉，我们并不能通过跟踪号码确认包裹地址。`,
  help_30: `track.a2a56.com是第三方独立站点，用于查询拉丁美洲市场邮政包裹以及国际快递包裹。我们只在网站中同步更新运输公司关于您的包裹的运输状态。但需要特别注意的是，我们并不直接参于具体的运输事宜，也不参与在线交易。`,
  help_31: `地址是私人信息，只有发货人（即卖家）和运输商知道您的包裹地址信息。如果需要确认地址，我们建议您直接与包裹发件人联系。`,
  help_32: `8. 挂号包裹及平邮有什么区别？`,
  help_33: `目前跨境电子商务小包裹物流的主要物流方式，发货人在寄送国际包裹时有多种选择，其中包括挂号邮包、快递及各种专线类渠道。`,
  help_34: `挂号邮政是一种可跟踪的邮政运输服务，而平邮则是一种不具有跟踪信息的运输方式。`,
  help_35: `9. 我需要等多久才可以收到我的包裹？`,
  help_36: `很抱歉，运输商并没有承诺的到达的时间限制，所以track.a2a56.com也无法获得预计您可以收到包裹的时间。`,
  help_37: `但是基于我们的数据统计分析，平邮或挂号包裹通常需要15至35天完成投递过程。`,
  help_38: `10. track.a2a56.com能确认我的追踪号码是否有效吗？`,
  help_39: `我们可以根据跟踪单号规则确认单号是否为有效号码，但是我们无法确认该包裹是否寄给你本人或是其他人。`,
  help_40: `所以请尽可能选择信誉好的卖家，谨慎起见，请在下单前详细了解卖家的店铺情况，以保证互联网交易安全。`,
  help_41: `track.a2a56.com是第三方独立站点，用于拉丁美洲市场的邮政包裹以及国际快递包裹。但需要特别注意的是，我们并不直接参于具体的运输事宜，也不参与在线交易。`,
  help_42: `11. 包裹状态显示“运输过久”，是什么意思？`,
  help_43: `“运输过久”表示您的包裹可能会遇到被延期送货的情况，如果同时物流信息已经很长时间没有更新，如果超过30天您仍未收到包裹，我们建议您及时与发货人联系，以便他们调查自身是否已经发货，同时从发货国家的承运方开始调查。`,
  help_44: `12. 为什么我的包裹派送到错误的地址？`,
  help_45: `目前对于包裹的处理大多是自动化流程，但是在整个处理过程仍然涉及到大量的人工手动操作，所以会偶有发生投递失误的情况。尽管这种情况发生的比例非常小，但如果您遇到了包裹投递错误的情况，请及时您的发货人，或购买商品的商店，确认您的地址细节是否正确，并且联系目的地的运输商进行处理或者访问运输商的官方网站获得进一步的帮助。`,
  help_46: `13. 为什么我的包裹处于“可能异常”状态？`,
  help_47: `“可能异常”状态表示您的包裹遇到了由一些不确定的原因引起的非正常状况。最常见的几种原因就是：包裹由于地址不完整/涉及海关调查问题/违禁货物/侵权商品/丢失或损坏等原因被退回发件人或暂存在目的国海关仓库或运输商的操作中心。退回包裹的原因会写在包裹上。`,
  help_48: `如果您的包裹显示异常，请咨询当地运输商或海关寻求帮助。`,
  help_49: `14. 如果我的包裹需要清关，我该怎么办？`,
  help_50: `对于国际快递，海关申报和清关是一个强制性的程序。`,
  help_51: `如果您的包裹被海关调查并且依法扣押，您可以联系运输商来了解目的国如何清关，也可以与海关联系了解需要准备哪些清关材料。`,
  help_52: `15. 包裹状态显示“投递失败”，代表什么意思？`,
  help_53: `“投递失败”的状态表示您的包裹运输商已经尝试过投递，但是未能交付成功。`,
  help_54: `大部分邮政运输商会在短时间内暂时保留您的包裹。如果您错过了第一次派送，则需要与当地运输商联系，协调进行第二次送货。`,
  help_55: `如果在投递失败后，没有任何关于此包裹修改派送地址的指示，包裹将被退回原发件人。`,
  help_56: `16. 如果地址不完整，包裹是否可以顺利到达？`,
  help_57: `大部分情况下，包裹是可以投妥的。但是也有可能会延误，这取决于您是否提供了准确、完整、详细的地址。`,
  help_58: `包裹在运输过程中会经过多次的分拣及处理，如果地址不完整、充分，那么有可能会发去错误的中转站，这样就会造成延误。如果地址不详细并缺少主要信息，则会因为无法投递而退回给发件人。`,
  help_59: `17. 我的包裹在“到达待取”状态，我需要去自取包裹吗？`,
  help_60: `“到达待取”表示您的包裹已经到达目的地国家的街区快递站或者正在派送中，收件人可以尝试联系运输商询问投递时间或者由您自己取货。`,
  help_61: `18. 包裹状态“运输途中”很长一段时间了，它的具体位置在哪里？`,
  help_62: `国际运输的时间通常比较久。根据我们的时效统计，通常小包需要约15-30天，ePacket需要7-20天，EMS则需14天左右。如果您在a2a56.com查询得到的位置结果不能令您满意，我们建议可以通过联系发件人或运输商，得到更多信息。`,
  help_63: `19. 跟踪信息显示留下了派送通知单，为什么我没有收到？`,
  help_64: `包裹派送通知单是一张纸质便签，一般会由派送员投入收件人地址的信箱或者门缝。如果找不到通知单，最简单的方式就是请您与当地的运输商直接联系。他们会告知您包裹的具体取件位置。`,
  help_65: `20. 包裹状态显示“投递成功”，但我没有收到，我该怎么办？`,
  help_66: `正常情况下，“投递成功”表示包裹已经交付给收件人。如果收件人并未收到包裹，你可以尝试以下解决方案来了解包裹的实际情况：与发货方确认收件地址/询问是否被其他人代收/联系此包裹的运输公司。`,
  help_67: `21. 我从中国购买的订单，为什么显示发件国家为“美国”？`,
  help_68: `在中国有各种各样的运输商可供在线商店运营商选择，特别是在电子商务行业。例如西班牙邮政、加拿大邮政等等。`,
  help_69: `当您在网上订购物品时，卖家会根据各种不同因素综合考虑，然后选择他们认为的最适合您的运输服务。`,
  help_70: `22. 包裹被派送到错误的地址并签收，该怎么办？`,
  help_71: `如果你确认你的包裹已投递给其他人，请直接与发件人联系，确认发货单上面地址是否正确。如果正确，你可以直接联系当地运输商核对您的包裹派送信息。`,
  help_72: `track.a2a56.com是第三方独立站点，专注于查询拉丁美洲市场的邮政包裹以及国际快递包裹。但需要特别注意的是，我们并不直接参于您包裹的具体运输事宜。`,
  delivered: `派送成功`,
  in_transit: `开始发货`,
  expired: `已到期`,
  alert: `警报`,
  not_found: `未知号码/无轨迹`,
  locate: `定位`,
  terms_of_use: `使用条款`,
  copyright01: `版权所有`,
  about: `关于我们`,
  support: `技术支持`,
  comment: `留言`,
  help01: `帮助`,
  about02: `关于我们：`,
  contact_01: `a2a56 是一个跟踪国际快递的信息平台，提供来自多个国际快递/承运商的快递/物流信息`,
  contact_02: `请您注意， a2a56 不负责交付您的订单。 对于丢失或损坏的包裹和包裹以及任何其他交付问题，您应该联系发货人或您签发订单的地方或负责交付包裹的承运商/物流商。`,
  contact_form_01: `这里可以聊聊您的想法，我们将在 24 小时内为您提供个性化帮助。`,
  contact_form_02: `您或您公司的名字`,
  contact_form_03: `您的电子邮箱`,
  contact_form_04: `关于快递信息的问题`,
  contact_form_05: `API和Webhooks的问题`,
  contact_form_06: `其他`,
  contact_form_07: `请在这里告诉我们任何您的想法或者建议`,
  contact_form_08: `发送`,
  contact_form_09: `选择一个主题吧`,
  contact_03: `需要帮助吗？`,
  contact_04: `访问我们的帮助中心并查找关于您的疑问的各种信息。`,
  no_results_header: `结果不存在`,
  no_results_text: `请核对您的包裹跟踪号码`,
  hy: `你好`,
  how_can_we_help_you: `你有任何问题需要我们的帮助？`,
  search_in_our: `搜索我们的知识库或浏览以下问题。`,
  faq: `常见问题`,
  help_center_button: `a2a56帮助中心`,
  table_of_contents: `目录`,
  what_is: `什么是`,
  "31_track": `a2a56？`,
  what_is_description: `是拉美领先的物流信息服务平台，为拉美物流快递提供个性化的信息流服务。`

};
